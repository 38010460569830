import React, { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { UseAuth } from '../../hooks/use-auth';
import { UserRole } from '../../types/enum/user-role-types';
import { DecodeToken } from 'utils/decode-token';

interface PrivateRouteProps {
    element: React.ReactElement;
    allowedRoles: UserRole[];
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element, allowedRoles }) => {
    const token = localStorage.getItem('workflow-platform-token-auth');
    const location = useLocation();
    const navigate = useNavigate(); 

    useEffect(() => {
        if (token) {
            const claims = DecodeToken(token);

            if (claims && claims.exp && claims.exp < Date.now() / 1000) {
                navigate('/', { state: { from: location } });
                return;
            }

            const profile = claims?.profile.toLowerCase();

            const hasAccess = allowedRoles.some(role => role.toLowerCase() === profile);

            if (!hasAccess) {
                if (profile === UserRole.Administrator.toLowerCase()) {
                    navigate('/Workspace', { state: { from: location } });
                } else if (profile === UserRole.Operator.toLowerCase()) {
                    navigate('/Manage', { state: { from: location } });
                } else {
                    navigate('/Tickets', { state: { from: location } });
                }
            }
        } else {
            navigate('/', { state: { from: location } });
        }
    }, [token, navigate, location, allowedRoles]);

    return element;
};


export default PrivateRoute;