import { AccountViewModel } from '../types/models/account-view-model';
import { ApiResponse, PagedResult, PaginationDefaultRequest, PostApiResponse } from '../types/response/api-types';
import AuthenticationService from './login/auth-service';
import { Account } from '../types/account-types';
import Config from 'config';

const API_URL = `${Config.API_URL}${Config.Endpoints.Account}`;

const AccountService = {
    GetPagedAccounts: async (request: PaginationDefaultRequest): Promise<PostApiResponse<PagedResult<AccountViewModel>>> => {
        try {
            const token = AuthenticationService.GetToken();
            const response = await fetch(`${API_URL}/getpagedaccounts`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` 
                },
                body: JSON.stringify(request)
            });

            const data: PostApiResponse<PagedResult<AccountViewModel>> = await response.json();
            if (data.success) {
                return data;
            } else {
                return {
                    success: false,
                    message: "Falha ao obter os usuários.",
                    data: {} as  PagedResult<AccountViewModel>
                };
            }
        } catch (error) {
            return {
                success: false,
                message: "Falha ao obter as usuários.",
                data: {} as  PagedResult<AccountViewModel> 
            };
        }
    },
    CreateAccount: async(model: AccountViewModel): Promise<PostApiResponse<boolean>> => { 
        try {
            const token = AuthenticationService.GetToken();

            const account: Account = {
                id: model.id,
                accessProfileId: model.accessProfileId,
                profileTypeId: model.profileTypeId,
                name: model.name,
                emailAddress: model.email,
                passwordHash: model.passwordHash,
                passwordSalt: '',
                passwordResetToken: '',
                passwordResetTokenExpiry: null,
                lastLogin: null,
                telephone: '',
                registration: '',
                status: model.status,
                accountAccessModules: model.accountAccessModules
            };

            const requestData = {
                Account: account
            };

            const response = await fetch(`${API_URL}/createaccount`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`  
                },
                body: JSON.stringify(requestData),
            });

            const data: PostApiResponse<boolean> = await response.json();
            if (data.success) {
                return data;
            } else {
                return {
                    success: false,
                    message: "Falha ao criar novo usuário.",
                    data: false
                };
            }
        } catch (error) {
            return {
                success: false,
                message: "Falha ao criar novo usuário.",
                data: false
            };
        }
    },
    UpdateAccount: async(model: AccountViewModel): Promise<PostApiResponse<boolean>> => { 
        try {
            const token = AuthenticationService.GetToken();

            const account: Account = {
                id: model.id,
                accessProfileId: model.accessProfileId,
                profileTypeId: model.profileTypeId,
                name: model.name,
                emailAddress: model.email,
                passwordHash: model.passwordHash,
                passwordSalt: '',
                passwordResetToken: '',
                passwordResetTokenExpiry: null,
                lastLogin: null,
                telephone: '',
                registration: '',
                status: model.status,
                accountAccessModules: model.accountAccessModules
            };

            const requestData = {
                Account: account
            };

            const response = await fetch(`${API_URL}/updateaccount`, {
                method: 'PUT',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`  
                },
                body: JSON.stringify(requestData),
            });

            const data: PostApiResponse<boolean> = await response.json();
            if (data.success) {
                return data;
            } else {
                return {
                    success: false,
                    message: "Falha ao atualizar o usuário",
                    data: false
                };
            }
        } catch (error) {
            return {
                success: false,
                message: "Falha ao atualizar o usuário",
                data: false
            };
        }
    },
    GetAllOperatorsByAccountId: async (accountId: string, accessProfileId: string): Promise<ApiResponse<AccountViewModel>> => {
        try {
            const token = AuthenticationService.GetToken();
            const response = await fetch(`${API_URL}/getalloperatorsbyaccountid`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` 
                },
                body: JSON.stringify({ AccountId: accountId, AccessProfileId: accessProfileId})
            });

            const data: ApiResponse<AccountViewModel> = await response.json();
            if (data.success) {
                return data;
            } else {
                return {
                    success: false,
                    message: "Falha ao obter as usuários.",
                    data: [] 
                };
            }
        } catch (error) {
            return {
                success: false,
                message: "Falha ao obter as usuários.",
                data: [] 
            };
        }
    }
}

export default AccountService;
