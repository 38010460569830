const Config = {
    API_URL: 'https://upbeat-yonath.65-181-111-22.plesk.page/api',
    Endpoints: {
        AccessProfile: '/AccessProfile',
        Authentication: '/Authentication',
        Account: '/Account',
        Color: '/Color',
        Module: '/Module',
        Notification: '/Notification',
        Priority: '/Priority',
        ProfileType: '/ProfileType',
        TicketFeedback: '/TicketFeedback',
        Ticket: '/Ticket',
        TicketType: '/TicketType'
    },
};
  
export default Config;