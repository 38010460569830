import React, { useEffect, useState } from 'react';
import "./style.css";
import { TicketStatus } from '../../types/enum/ticket-status-enum-types';
import { TokenClaims } from '../../types/token-claims';
import { DecodeToken } from 'utils/decode-token';
import AuthenticationService from 'services/login/auth-service';
import { GetByListTicketStatusIdRequest } from '../../types/response/api-types';
import TicketService from 'services/ticket-service';
import { TicketViewModel } from '../../types/models/ticket-view-model';
import { Skeleton, Table } from 'antd';
import { FilterState } from '../../types/filter-state-types';
import { PriorityCount } from '../../types/priority-count-types';

interface CustomTablePaginationProps {
    columns: any[];
    listTicketStatus: TicketStatus[];
    filters?: FilterState;
    priority?: PriorityCount;
}

const CustomTablePagination: React.FC<CustomTablePaginationProps> = ({ columns, listTicketStatus, filters, priority }) => {
    const [claims, setClaims] = useState<TokenClaims>({} as TokenClaims);
    const [tickets, setTickets] = useState<TicketViewModel[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [title, setTitle] = useState<string | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(5);
    const [total, setTotal] = useState<number>(0);

    useEffect(() => {
        const token = AuthenticationService.GetToken();

        if (token) {
            const decoded = DecodeToken(token);
            if (decoded) {
                setClaims(decoded);
            }
        }
    }, []);

    useEffect(() => {
        if (claims.accountid && listTicketStatus.length > 0 || priority) {
            const fetchTickets = async () => {
                setLoading(true);

                const hasValidFilters = (filters: FilterState | undefined): boolean => {
                    if (!filters) return false;
                    return (Object.keys(filters) as Array<keyof FilterState>).some(key => filters[key] !== undefined);
                };

                const request: GetByListTicketStatusIdRequest = {
                    SearchTerm: '',
                    AccountId: claims.accountid,
                    ProfileId: claims.profileid,
                    ListTicketStatusId: listTicketStatus,
                    PriorityId: priority?.id,
                    Query: {
                        Page: currentPage,
                        PageSize: pageSize
                    },
                    ...(filters && hasValidFilters(filters) ? { Filter: filters } : {})
                };
                
                const response = await TicketService.GetByListTicketStatusId(request);

                setTitle(formatTitle());
                if (response.success) {
                    setTickets(response.data.items);
                    setTotal(response.data.count);
                } else {
                    setError(response.message || 'Falha ao obter tickets.');
                }

                setLoading(false);
            };

            fetchTickets();
        }
    }, [claims.accountid, listTicketStatus, currentPage, pageSize]);

    const handleTableChange = (pagination: any) => {
        setCurrentPage(pagination.current);
        setPageSize(pagination.pageSize);
    };

    const formatTitle = () => {
        const menuOptions = [
            { label: 'Abertos', listStatus: [TicketStatus.Registrado] },
            { label: 'Em Atendimento', listStatus: [TicketStatus.EmAndamento, TicketStatus.EsperandoAnexos, TicketStatus.Reaberto] },
            { label: 'Encerrados', listStatus: [TicketStatus.Encerrado] },
        ];
    
        if (listTicketStatus && listTicketStatus.length > 0) {
            const option = menuOptions.find(option => 
                option.listStatus.length === listTicketStatus.length && 
                option.listStatus.every(status => listTicketStatus.includes(status))
            );
            
            if (option) {
                return option.label;
            }
    
            return 'Disponíveis';
        } 
        else if (priority && priority.name) {
            return priority.name;
        }
    
        return 'Disponíveis';
    };

    const components = {
        body: {
          wrapper: (props: any) => (
            <>
              <thead>
                <tr>
                  <td className='container-description-table' colSpan={columns.length}>
                    <div>
                        Chamados: <span>{title}</span> 
                    </div>
                  </td>
                </tr>
              </thead>
              <tbody {...props} />
            </>
          ),
        },
    };

    return (
        <div className='container-table-tickets'>
            {loading ? (
                <Skeleton active paragraph={{ rows: 10 }} />
            ) : (
                <Table
                    columns={columns}
                    dataSource={tickets}
                    rowKey="id"
                    loading={loading}
                    components={components}
                    pagination={{
                        current: currentPage,
                        pageSize: pageSize,
                        total: total,
                        showSizeChanger: true,
                        pageSizeOptions: ['5', '10', '25', '50', '100'],
                        showTotal: (total: number) => `Total ${total} itens`,
                        locale: {
                            items_per_page: 'Itens por página',
                            jump_to: 'Ir para',
                            jump_to_confirm: 'Confirmar',
                            page: 'Página',
                            prev_page: 'Página anterior',
                            next_page: 'Próxima página',
                            prev_5: '5 páginas anteriores',
                            next_5: '5 páginas seguintes',
                            prev_3: '3 páginas anteriores',
                            next_3: '3 páginas seguintes',
                        },
                    }}
                    onChange={handleTableChange}
                    locale={{
                        emptyText: 'Nenhum chamado foi encontrado. Tente ajustar os filtros ou adicionar novos chamados.'
                    }}
                />
            )}
        </div>
    );
};

export default CustomTablePagination;