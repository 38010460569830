import React, { useState, useEffect, useRef, useCallback } from 'react';
import "./style.css";
import logo from '../../assets/Logo.png';
import CustomInputText from '../../components/custom-input-text';
import CustomInputPassword from '../../components/login/custom-input-password';
import CustomInputCheckbox from '../../components/custom-input-checkbox';
import CustomButton from '../../components/custom-button';
import AuthenticationService from 'services/login/auth-service';
import { LoginResponse, PostApiResponse } from '../../types/response/api-types';
import { useLocation, useNavigate } from "react-router-dom";
import ForgotPassword from 'pages/forgot-password';
import RecoverPassword from 'pages/recover-password';
import CustomNotification from 'components/custom-notification';
import { Box, Spinner } from '@chakra-ui/react';

function Login() {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [recoverId, setRecoverId] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [modalConfig, setModalConfig] = useState<{ isOpen: boolean, type: 'forgot' | 'recover' }>({
        isOpen: false,
        type: 'forgot'
    });

    const modalRef = useRef<HTMLDivElement>(null);
    const recoverPasswordModalRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const savedEmail = localStorage.getItem('email') || '';
        const savedPassword = localStorage.getItem('password') || '';
        const savedStayConnected = localStorage.getItem('stayConnected') === 'true';
        
        setEmail(savedEmail);
        setPassword(savedPassword);
        setIsChecked(savedStayConnected);
    }, []);

    useEffect(() => {
        if (isChecked) {
            localStorage.setItem('email', email);
            localStorage.setItem('password', password);
        }
    }, [email, password, isChecked]);

    useEffect(() => {
        if (location.search.includes('RecoverPassword')) {
            const queryParams = new URLSearchParams(window.location.search);  
            const recoverID = queryParams.get('RecoverPassword');
            
            if (recoverID) {
                setRecoverId(recoverID);

                const validateToken = async () => {
                    const response = await AuthenticationService.ValidatePasswordRecoveryExpiration(recoverID);
                    if(response.data.allowed) {
                        openModal('recover');
                    } else {
                        CustomNotification({
                            title: 'Algo deu errado!',
                            description: response.message || 'Ocorreu um erro ao validar o token de recuperação. Por favor, tente novamente.',
                            notificationType: 'error',
                            placement: 'top-right'
                        });
                        navigate('/Login');
                    }
                }
                validateToken();
            }
        }
    }, [location.search, navigate]);

    const handleClickOutside = useCallback((event: MouseEvent) => {
        if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
            closeModal();
        }

        if (recoverPasswordModalRef.current && !recoverPasswordModalRef.current.contains(event.target as Node)){
            closeModal();
            navigate('/Login');
        }
    }, [navigate]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    const validateLoginFields = (): boolean => {
        if (email.length < 10 || password.length < 8) {
            CustomNotification({
                title: 'Atenção!',
                description: 'Necessário preencher os campos para fazer o login.',
                notificationType: 'warning',
                placement: 'top-right'
            });
            return false;
        }
        return true;
    };

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        
        if (!validateLoginFields()) return;

        try {
            setLoading(true);
            const response: PostApiResponse<LoginResponse> = await AuthenticationService.Login(email, password);
            setLoading(false);
            console.log("User logged in successfully:", response);

            if(response.success && response.data.token !== null) {
                if (isChecked) {
                    localStorage.setItem('stayConnected', 'true');
                } else {
                    localStorage.removeItem('stayConnected');
                    localStorage.removeItem('email');
                    localStorage.removeItem('password');
                }

                if(response.data.role.toLocaleUpperCase() === "6DB8C910-96A1-495D-BC6A-F0E826DDF3BE"){
                    navigate('/Workspace');
                }else if(response.data.role.toLocaleUpperCase() === "8AB65A56-53F0-475B-A863-0313285A540F"){
                    navigate('/Manage');
                }else{
                    navigate('/Tickets');
                }
            } else {
                CustomNotification({
                    title: 'Algo deu errado!',
                    description: response.message || 'Ocorreu um erro durante o login. Por favor, tente novamente.',
                    notificationType: 'error',
                    placement: 'top-right'
                });
            }
        } catch (err: any) {
            setError(err.message);
        }
    };

    const openModal = (type: 'forgot' | 'recover') => setModalConfig({ isOpen: true, type });
    const closeModal = () => setModalConfig({ isOpen: false, type: 'forgot' });

    const handleCheckboxChange = useCallback(() => {
        setIsChecked(prev => !prev);
    }, []);

    return (
        <div className='container-center'>
            {loading && (
                <Box
                    position="absolute"
                    top="0"
                    left="0"
                    width="100%"
                    height="100%"
                    bg="rgba(255, 255, 255, 0.8)"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    zIndex="10"
                >
                    <Spinner size="xl" />
                </Box>
            )} 
            <div className='container-main'>
                <div className='container-content-login'>               
                    <div className='container-logo'>
                        <img src={logo} alt="Logo instituto votoratim" />
                        <p className='title-login'>Plataforma de Workflow</p>
                    </div>
                    <div className='container-login'>                    
                        <form onSubmit={handleLogin}>
                            <div className='container-fields-login'>
                                <CustomInputText 
                                    placeholder='Login/Usuário'
                                    type='email'
                                    label='Usuário'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}       
                                /> 
                                
                                <CustomInputPassword 
                                    label='Senha'
                                    placeholder='Senha'
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)} 
                                    onForgotPasswordClick={() => openModal('forgot')}  
                                /> 
                                
                                <CustomInputCheckbox 
                                    label='Mantenha-se conectado'
                                    isChecked={isChecked}
                                    onCheckboxChange={handleCheckboxChange}
                                />  

                                <div className='container-password-login'>
                                    <CustomButton labelText="Realizar Login" variant='primary' type="submit" />                      
                                </div>
                            </div>
                        </form>                               
                    </div>
                </div>
            </div>
            <div className='container-footer'></div>

            <ForgotPassword isOpen={modalConfig.isOpen && modalConfig.type === 'forgot'} onClose={closeModal} />
            
            <RecoverPassword isOpen={modalConfig.isOpen && modalConfig.type === 'recover'} recoverId={recoverId} onClose={closeModal} />
        </div>
    )
}

export default Login;