import React, { useEffect, useState } from 'react';
import "./style.css";
import { TicketStatus } from '../../types/enum/ticket-status-enum-types';
import { TokenClaims } from '../../types/token-claims';
import { DecodeToken } from 'utils/decode-token';
import AuthenticationService from 'services/login/auth-service';
import { GetByListTicketStatusIdRequest } from '../../types/response/api-types';
import TicketService from 'services/ticket-service';
import { TicketViewModel } from '../../types/models/ticket-view-model';
import { Skeleton, Table } from 'antd';
import CustomButton from 'components/custom-button';
import { FilterState } from '../../types/filter-state-types';

interface CustomTablePaginationButtonProps {
    columns: any[];
    listTicketStatus: TicketStatus[];
    filters?: FilterState;
    labelText: string;
    priorityId?: string;
    onGetTicket?: (tickets: TicketViewModel[]) => void; 
}

const CustomTablePaginationButton: React.FC<CustomTablePaginationButtonProps> = ({ columns, labelText, filters, listTicketStatus, priorityId, onGetTicket}) => {
    const [claims, setClaims] = useState<TokenClaims>({} as TokenClaims);
    const [tickets, setTickets] = useState<TicketViewModel[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize] = useState<number>(5);
    const [total, setTotal] = useState<number>(0);


    useEffect(() => {
        const token = AuthenticationService.GetToken();

        if (token) {
            const decoded = DecodeToken(token);
            if (decoded) {
                setClaims(decoded);
            }
        }
    }, []);

    useEffect(() => {
        if (claims.accountid && listTicketStatus.length > 0) {
            const fetchTickets = async () => {
                setLoading(true);
        
                const hasValidFilters = (filters: FilterState | undefined): boolean => {
                    if (!filters) return false;
                    return (Object.keys(filters) as Array<keyof FilterState>).some(key => filters[key] !== undefined);
                };
        
                const request: GetByListTicketStatusIdRequest = {
                    SearchTerm: '',
                    AccountId: claims.accountid,
                    ListTicketStatusId: listTicketStatus,
                    PriorityId: priorityId,
                    ProfileId: claims.profileid,
                    Query: {
                        Page: currentPage,
                        PageSize: pageSize
                    },
                    ...(filters && hasValidFilters(filters) ? { Filter: filters } : {})
                };
                
                const response = await TicketService.GetByListTicketStatusId(request);
        
                if (response.success) {
                    setTickets(prevTickets => [...prevTickets, ...response.data.items]);
                    setTotal(response.data.count);
                    if (onGetTicket) {
                        onGetTicket(response.data.items);
                    }
                } else {
                    setError(response.message || 'Falha ao obter tickets.');
                }
        
                setLoading(false);
            };
            fetchTickets();
        }
    }, [claims.accountid, currentPage]);

    const loadMore = () => {
        setCurrentPage(currentPage + 1);
    };

    const generateShortId = () => {
        return Math.random().toString(36).substr(2, 8);
    };

    const components = {
        body: {
          wrapper: (props: any) => (
            <>
              <thead>
                <tr>
                  <td className='container-description-table' colSpan={columns.length}>
                    <div>
                        Chamados: <span>{labelText}</span> 
                    </div>
                  </td>
                </tr>
              </thead>
              <tbody {...props} />
            </>
          ),
        },
    };

    return (
        <div className='container-table-pagination-button'>
            {loading ? (
                <Skeleton active paragraph={{ rows: (tickets.length*pageSize)}} />
            ) : (
                <div>
                    <Table
                        columns={columns}
                        dataSource={tickets}
                        rowKey={() => generateShortId()}
                        loading={loading}
                        pagination={false} 
                        components={components}
                        locale={{
                            emptyText: 'Nenhum chamado foi encontrado. Tente ajustar os filtros ou adicionar novos chamados.'
                        }}
                        />
                    {tickets.length < total && ( 
                        <div className='container-button-table-pagination'>
                            <CustomButton labelText='Carregar mais' variant="secondary" onClick={loadMore}/>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default CustomTablePaginationButton;