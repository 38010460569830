import React, { useCallback, useEffect, useState } from 'react';
import "../../styles/global.css";
import { Navbar } from 'react-bootstrap';
import Sidebar from 'components/sidebar';
import CustomButton from 'components/custom-button';
import InputWithLabel from 'components/custom-input-text-label';
import TextareaWithLabel from 'components/custom-textarea-label';
import { useNavigate, useLocation } from 'react-router-dom';
import Select from 'antd/es/select';
import ColorService from 'services/color-service';
import { Priority } from '../../types/priority-types';
import PriorityService from 'services/priority-service';
import { Checkbox } from 'antd';
import { Box, Spinner } from '@chakra-ui/react';
import CustomNotification from 'components/custom-notification';

function CreateOrEditPriority() {
    const [currentPriority, setCurrentPriority] = useState<Priority>({
        id: '00000000-0000-0000-0000-000000000000',
        name: '',
        description: '',
        status: true,
        colorId: '',
        prioritySLA: '10 horas' 
    });
    
    const [colors, setColors] = useState<{ value: string, label: string, color: string }[]>([]);
    const [errors, setErrors] = useState<{ name?: boolean, description?: boolean, colorId?: boolean }>({});
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { Option } = Select;

    useEffect(() => {
        if (location.state?.rowData) {
            const priorityData = location.state.rowData as Priority;
            setCurrentPriority(priorityData);
            setIsEditing(true);
        }
    }, [location.state]);

    const fetchColors = useCallback(async () => {
        const response = await ColorService.GetAll();
        if (response.success && response.data) {
            const formattedColors = response.data.map(color => ({
                value: color.id,
                label: color.name,
                color: color.hexadecimal
            }));
            setColors(formattedColors);
        } else {
            console.error(response.message);
        }
    }, []);

    useEffect(() => {
        fetchColors();
    }, [fetchColors]);

    const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setCurrentPriority(prev => ({ ...prev, [name]: value }));
        setErrors(prev => ({ ...prev, [name]: false }));
    }, []);

    const handleColorChange = useCallback((value: string) => {
        setCurrentPriority(prev => ({ ...prev, colorId: value }));
        setErrors(prev => ({ ...prev, colorId: false }));
    }, []);

    const handleBack = () => {
        navigate(-1);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const newErrors: { name?: boolean, description?: boolean, colorId?: boolean } = {};

        if (!currentPriority.name.trim()) {
            CustomNotification({
                title: 'Atenção!',
                description: 'O campo Nome é obrigatório.',
                notificationType: 'warning',
                placement: 'top-right'
            });
            newErrors.name = true;
        }
    
        if (!currentPriority.description.trim()) {
            CustomNotification({
                title: 'Atenção!',
                description: 'O campo Descrição é obrigatório.',
                notificationType: 'warning',
                placement: 'top-right'
            });
            newErrors.description = true;
        }

        if (!currentPriority.colorId.trim()) {
            CustomNotification({
                title: 'Atenção!',
                description: 'É necessário escolher uma cor.',
                notificationType: 'warning',
                placement: 'top-right'
            });
            newErrors.colorId = true;
        }

        if (newErrors.name || newErrors.description || newErrors.colorId) {
            setErrors(newErrors);
            return;
        }

        setLoading(true);
        try {
            let result;
            if (isEditing) {
                result = await PriorityService.UpdatePriority(currentPriority);
            } else {
                result = await PriorityService.CreatePriority(currentPriority);
            }

            if (result.success) {
                CustomNotification({
                    title: 'Ação concluída com sucesso!',
                    description: isEditing ? 'Prioridade atualizada com sucesso!' : 'Prioridade criada com sucesso!',
                    notificationType: 'success',
                    placement: 'top-right'
                });
                navigate("/Workspace/Priorities");
            } else {
                CustomNotification({
                    title: 'Algo deu errado!',
                    description: result.message || 'Ocorreu um erro ao processar sua solicitação.',
                    notificationType: 'error',
                    placement: 'top-right'
                });
            }
        } catch (error) {
            console.error(error);
            CustomNotification({
                title: 'Erro',
                description: 'Ocorreu um erro ao se conectar com o servidor.',
                notificationType: 'error',
                placement: 'top-right'
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='container-crud'>
            <Sidebar />
            <Navbar />
            <div className='container-content-crud'>
                <div className='container-header-crud'>
                    <span>{isEditing ? "Editando Prioridade" : "Criando Nova Prioridade"}</span>
                    <div className='button-header-crud'>
                        <CustomButton variant='secondary' labelText='Voltar' onClick={handleBack} />
                    </div>
                </div>

                <div className='container-main-crud'>
                    <div className='container-title-crud'>
                        <span>Formulário de Prioridade</span>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className='container-fields-crud'>
                            {loading && (
                                <Box
                                    position="absolute"
                                    top="0"
                                    left="0"
                                    width="100%"
                                    height="100%"
                                    bg="rgba(255, 255, 255, 0.8)"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    zIndex="10"
                                >
                                    <Spinner size="xl" />
                                </Box>
                            )}  
                            <div className='container-field-checkbox-crud'>
                                <Checkbox 
                                    checked={currentPriority.status} 
                                    onChange={(e) => setCurrentPriority(prev => ({ ...prev, status: e.target.checked }))} 
                                    className='container-custom-checkbox-input' 
                                />
                                <label>Ativo</label>
                            </div>

                            <div className='container-field-text-crud'>
                                <div className='field-text-name-crud'>
                                    <InputWithLabel
                                        label='Nome'
                                        placeholder='Nome'
                                        name="name"
                                        maxLength={100}
                                        error={errors.name} 
                                        value={currentPriority.name}
                                        onChange={handleInputChange} 
                                    />
                                </div>
                                <div className={`container-select-color-assigned ${errors.colorId ? 'error-select' : ''}`}>
                                    <label>Cor atribuída</label>
                                    <Select
                                        value={currentPriority.colorId || undefined}
                                        style={{ width: 200 }}
                                        placeholder='Escolher uma cor'
                                        onChange={handleColorChange}
                                        className={errors.colorId ? 'error-select' : ''}
                                    >
                                        {colors.map(option => (
                                            <Option key={option.value} value={option.value}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <span
                                                        className='option-dot-color'
                                                        style={{ backgroundColor: option.color }}
                                                    />
                                                    {option.label}
                                                </div>
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>

                            <div className='container-textarea-crud'>
                                <TextareaWithLabel
                                    label='Descrição'
                                    placeholder='Escreva a Descrição'
                                    name="description"
                                    maxLength={250}
                                    error={errors.description}
                                    value={currentPriority.description}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <CustomButton
                                    type="submit"
                                    variant='primary'
                                    labelText={isEditing ? 'Atualizar' : 'Confirmar'}
                                    disabled={loading}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default CreateOrEditPriority;