import React, { useEffect, useState } from 'react';
import './style.css';
import Sidebar from 'components/sidebar';
import CustomButton from 'components/custom-button';
import { useNavigate } from 'react-router-dom';
import { AccountViewModel } from '../../types/models/account-view-model';
import { PaginationDefaultRequest } from '../../types/response/api-types';
import AccountService from 'services/account-service';
import CustomTableCrud from 'components/custom-table-crud';
import editIcon from '../../assets/icon_edit.png';
import { Checkbox } from 'antd';
import CustomInputSearch from 'components/custom-input-search';
import Navbar from 'components/header';
import CustomNotification from 'components/custom-notification';

const useFetchAccounts = (searchTerm: string, currentPage: number, pageSize: number) => {
    const [accounts, setAccounts] = useState<AccountViewModel[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [total, setTotal] = useState<number>(0);

    useEffect(() => {
        const fetchAccounts = async (): Promise<void> => {
            setLoading(true);
            setError(null); 

            const request: PaginationDefaultRequest = {
                SearchTerm: searchTerm,
                Query: {
                    Page: currentPage,
                    PageSize: pageSize,
                },
            };

            const response = await AccountService.GetPagedAccounts(request);

            if (response.success) {
                setAccounts(response.data.items);
                setTotal(response.data.count);
            } else {
                CustomNotification({
                    title: 'Algo deu errado!',
                    description: response.message || 'Falha ao obter os usuários.',
                    notificationType: 'error',
                    placement: 'top-right'
                });
            }

            setLoading(false);
        };

        fetchAccounts();
    }, [currentPage, pageSize, searchTerm]);

    return { accounts, loading, error, total };
};

const Users: React.FC = () => {
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(5);

    const navigate = useNavigate();
    const { accounts, loading, error, total } = useFetchAccounts(searchTerm, currentPage, pageSize);

    const handleCreate = () => {
        navigate('/Workspace/Users/Create');
    };

    const handleTableChange = (pagination: any) => {
        setCurrentPage(pagination.current || 1);
        setPageSize(pagination.pageSize || 5);
    };

    const onSearch = (search: string) => {
        setSearchTerm(search);
    };

    const handlerSearch = (search: string) => {
        if (search.length >= 3 || search.length === 0) {
            setSearchTerm(search);
        }
    };

    const formatCheckboxStatus = (rowData: AccountViewModel) => (
        <Checkbox disabled={true} checked={rowData.status} className='container-custom-checkbox-input' />
    );

    const formatEditAccount = (rowData: AccountViewModel) => {
        const handleClick = () => {
            navigate('/Workspace/Users/Edit', { state: { rowData } });
        };

        return (
            <div className='crud-icon-edit' onClick={handleClick}>
                <img src={editIcon} alt="Icone de editar" />
            </div>
        );
    };

    const columns = [
        {
            title: 'Ativo',
            render: (rowData: AccountViewModel) => formatCheckboxStatus(rowData),
            onHeaderCell: () => ({ style: { width: '10%' } }),
            onCell: () => ({ style: { width: '10%' } }),
        },
        {
            title: 'Nome',
            dataIndex: 'name',
            onHeaderCell: () => ({ style: { width: '20%' } }),
            onCell: () => ({ style: { width: '20%' } }),
        },
        {
            title: 'E-mail',
            dataIndex: 'email',
            onHeaderCell: () => ({ style: { width: '25%' } }),
            onCell: () => ({ style: { width: '25%' } }),
        },
        {
            title: 'Perfil',
            dataIndex: 'accessProfileName',
            onHeaderCell: () => ({ style: { width: '10%' } }),
            onCell: () => ({ style: { width: '10%' } }),
        },
        {
            title: 'Módulos Atribuídos',
            dataIndex: 'assignedModules',
            onHeaderCell: () => ({ style: { width: '25%' } }),
            onCell: () => ({ style: { width: '25%' } }),
        },
        {
            title: '',
            render: (rowData: AccountViewModel) => formatEditAccount(rowData),
            onHeaderCell: () => ({ style: { width: '10%' } }),
            onCell: () => ({ style: { width: '10%' } }),
        },
    ];

    return (
        <div className='container-main-user'>
            <Sidebar />
            <Navbar />
            <div className='container-content-user'>
                <div className='container-header-user'>
                    <span>Gerenciamento de Usuários</span>
                    <div className='container-header-actions-user'>
                        <div className='container-input-search-user'>
                            <CustomInputSearch onSearchClick={onSearch} onInputChange={handlerSearch} />
                        </div>
                        <div className='button-header-user'>
                            <CustomButton variant='primary' labelText='Novo usuário' onClick={handleCreate} />
                        </div>
                    </div>
                </div>

                <div>
                    <CustomTableCrud
                        columns={columns}
                        dataSource={accounts}
                        total={total}
                        loading={loading}
                        pageSize={pageSize}
                        currentPage={currentPage}
                        title='Usuários'
                        handleTableChange={handleTableChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default Users;