import React, { useCallback, useEffect, useState } from 'react';
import "./style.css";
import InputWithLabel from 'components/custom-input-text-label';
import TextareaWithLabel from 'components/custom-textarea-label';
import CustomButton from 'components/custom-button';
import Dropzone from 'components/dropzone';
import { DecodeToken } from 'utils/decode-token';
import { TokenClaims } from '../../types/token-claims';
import { format, parse } from 'date-fns';
import { Module } from '../../types/module-types';
import { TicketType } from '../../types/ticket-type-types';
import { Ticket } from '../../types/ticket-types';
import TicketService from 'services/ticket-service';
import { Box, Spinner } from '@chakra-ui/react';
import { TicketAttachment } from '../../types/ticket-attachment-types';
import PreviewImage from 'components/preview-image';
import { Select } from 'antd';
import CustomNotification from 'components/custom-notification';
import AuthenticationService from 'services/login/auth-service';

interface NewTicketProps {
    onTicketCreated: () => void;
    onCancel: () => void;
    setLoading: (loading: boolean) => void;
    modules: Module[];
    ticketTypes: TicketType[];
}

function NewTicket({ onTicketCreated, onCancel, setLoading, modules, ticketTypes }: NewTicketProps) {
    const [claims, setClaims] = useState<TokenClaims>({} as TokenClaims);
    const [errors, setErrors] = useState<{ subject?: boolean, description?: boolean, moduleId?: boolean, ticketTypeId?: boolean }>({});
    const [date, setDate] = useState<string>('');
    const [selectedModule, setSelectedModule] = useState<string>('');
    const [selectedTicketType, setSelectedTicketType] = useState<string>('');
    const [subject, setSubject] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [requesterName, setRequesterName] = useState<string>('');
    const [loading, setLocalLoading] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
    const { Option } = Select;

    const handleFilesChange = (files: File[]) => {
        setUploadedFiles(files); 
    };

    const handleAddFiles = (files: File[]) => {
        setUploadedFiles([...files, ...uploadedFiles]); 
    };
    
    const parseDate = (formattedStr: string): Date => {
        const formatString = "dd/MM/yyyy - HH'h'mm";
    
        const parsedDate = parse(formattedStr, formatString, new Date());

        const utcDate = new Date(parsedDate.getTime() - parsedDate.getTimezoneOffset() * 60000);

        return utcDate;
    };
    
    useEffect(() => {
        const token = AuthenticationService.GetToken();
        const now = new Date();   
        setDate(format(now, "dd/MM/yyyy - HH'h'mm"));

        if (token) {
            const decoded = DecodeToken(token);
            if(decoded){
                setRequesterName(decoded.name);
                setClaims(decoded);
            }
        }
    }, []);

    const fileToBase64 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onload = () => {
                if (fileReader.result) {
                    const base64 = (fileReader.result as string).split(',')[1];
                    resolve(base64);
                } else {
                    reject(new Error('Failed to read file content'));
                }
            };
            fileReader.onerror = () => {
                reject(new Error('Error reading file'));
            };
            fileReader.readAsDataURL(file);
        });
    };
    
    const transformFilesToTicketAttachment = async (files: File[]): Promise<TicketAttachment[]> => {
        const ticketAttachments = await Promise.all(files.map(async (file) => {
            const fileContent = await fileToBase64(file);
            return {
                id: '00000000-0000-0000-0000-000000000000',
                ticketId: '00000000-0000-0000-0000-000000000000',
                authorId: claims.accountid,
                fileName: file.name,
                filePath: 'testes',
                fileType: file.type,
                fileContent: fileContent,
                createdOn: new Date()
            };
        }));

        return ticketAttachments;
    };
    
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const newErrors: { subject?: boolean, description?: boolean, moduleId?: boolean, ticketTypeId?: boolean } = {};

        if (!selectedModule.trim()) {
            CustomNotification({
                title: 'Atenção!',
                description: 'É necessário escolher um módulo.',
                notificationType: 'warning',
                placement: 'top-right'
            });
            newErrors.moduleId = true;
        }
    
        if (!selectedTicketType.trim()) {
            CustomNotification({
                title: 'Atenção!',
                description: 'É necessário escolher um tipo.',
                notificationType: 'warning',
                placement: 'top-right'
            });
            newErrors.ticketTypeId = true;
        }

        if (!subject.trim()) {
            CustomNotification({
                title: 'Atenção!',
                description: 'O campo Assunto é obrigatório.',
                notificationType: 'warning',
                placement: 'top-right'
            });
            newErrors.subject = true;
        }

        if (!subject.trim()) {
            CustomNotification({
                title: 'Atenção!',
                description: 'O campo Descrição Detalhada é obrigatório.',
                notificationType: 'warning',
                placement: 'top-right'
            });
            newErrors.description = true;
        }

        if (newErrors.subject || newErrors.description || newErrors.moduleId || newErrors.ticketTypeId) {
            setErrors(newErrors);
            return;
        }

        setLoading(true);
        setLocalLoading(true);
        try {
            const ticket: Ticket = {
                id: '00000000-0000-0000-0000-000000000000',
                subject: subject,
                detailedDescription: description,
                moduleId: selectedModule,
                ticketTypeId: selectedTicketType,
                requesterId: claims.accountid,
                requestDate: parseDate(date),
                priorityId: null,
                ticketStatusId: null,
                ticketNumber: '',
                operatorId: null
            };
    
            const ticketAttachments = await transformFilesToTicketAttachment(uploadedFiles);
    
            const result = await TicketService.CreateTicket(ticket, ticketAttachments);

            if (result.success) {
                CustomNotification({
                    title: 'Ação concluída com sucesso!',
                    description: result.message || 'Chamado criado com sucesso.',
                    notificationType: 'success',
                    placement: 'top-right'
                });
                onTicketCreated();
            } else {
                CustomNotification({
                    title: 'Algo deu errado!',
                    description: result.message || 'Ocorreu um erro ao processar sua solicitação.',
                    notificationType: 'error',
                    placement: 'top-right'
                });
            }
        } catch (error) {
            console.error(error);
            CustomNotification({
                title: 'Erro',
                description: 'Ocorreu um erro ao se conectar com o servidor.',
                notificationType: 'error',
                placement: 'top-right'
            });
        } finally {
            setLoading(false);
            setLocalLoading(false);
        }
    };

    const handleFieldChange = (field: keyof typeof errors, value: string) => {
        setErrors((prevErrors) => ({
            ...prevErrors,
            [field]: !value.trim()
        }));
    };

    return(
        <div className='container-main-new-ticket'>
            <div className='container-title-new-ticket'>
                <span>Criação de Chamado</span>
            </div>

            <div className='container-form-new-ticket'>
            {loading && ( 
                    <Box
                        position="absolute"
                        top="0"
                        left="0"
                        width="100%"
                        height="100%"
                        bg="rgba(255, 255, 255, 0.8)"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        zIndex="10"
                    >
                        <Spinner size="xl" />
                    </Box>
                )}
                <form onSubmit={handleSubmit}>
                    <div className='container-fields-new-ticket'>
                        <div>
                            <InputWithLabel value='00000' disabled={true} label='ID' />
                        </div>
                        <div>
                            <InputWithLabel value={requesterName} disabled={true} label='Solicitante' />
                        </div>
                        <div>
                            <InputWithLabel value={date} disabled={true} label='Data/Hora da Solicitação' />
                        </div>
                        <div>
                            <InputWithLabel value='00/00/0000 - 00h00' disabled={true} label='Data/Hora do Encerramento' />
                        </div>
                    </div>

                    <div className='container-selects-new-ticket'>
                        <div className={`container-select-module ${errors.moduleId ? 'error-select' : ''}`}>
                            <label>Módulo</label>
                            <Select
                                value={selectedModule || undefined}
                                style={{ width: 200 }}
                                placeholder='Escolhe um módulo'
                                onChange={(e) => {
                                    setSelectedModule(e);
                                    if (errors.moduleId) {
                                        setErrors((prevErrors) => ({ ...prevErrors, moduleId: false }));
                                    }
                                }}
                                className={errors.moduleId ? 'error-select' : ''}
                                getPopupContainer={trigger => trigger.parentNode}
                            >
                                {modules.map(option => (
                                    <Option key={option.id} value={option.id}>
                                        {option.name}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        <div className={`container-select-type ${errors.ticketTypeId ? 'error-select' : ''}`}>
                            <label>Tipo</label>
                            <Select
                                value={selectedTicketType || undefined}
                                style={{ width: 200 }}
                                placeholder='Escolhe um tipo'
                                onChange={(e) => {
                                    setSelectedTicketType(e);
                                    if (errors.ticketTypeId) {
                                        setErrors((prevErrors) => ({ ...prevErrors, ticketTypeId: false }));
                                    }
                                }}
                                getPopupContainer={trigger => trigger.parentNode}
                            >
                                {ticketTypes.map(option => (
                                    <Option key={option.id} value={option.id}>
                                        {option.name}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    </div>

                    <div className='container-textarea-new-ticket'>
                        <div className='container-textarea-subject'>
                            <TextareaWithLabel 
                                placeholder='Nomeie como se fosse o título do chamado' 
                                label='Assunto' 
                                value={subject}
                                error={errors.subject}
                                onChange={(e) => {
                                    setSubject(e.target.value);
                                    handleFieldChange('subject', e.target.value);
                                }}
                            />
                        </div>
                        <div className='container-textarea-description'>
                            <TextareaWithLabel 
                                placeholder='Descrição do seu problema relatando ações e processos que levaram ao erro.' 
                                label='Descrição Detalhada' 
                                value={description}
                                error={errors.description}
                                onChange={(e) => {
                                    setDescription(e.target.value);
                                    handleFieldChange('description', e.target.value);
                                }}
                            />
                        </div>
                    </div>

                    <div className='container-attachments-new-ticket'>
                        <div className='container-attachments-title'>
                            <span>Anexos</span>
                            <p>Insira os anexos que demonstram o problema, comtemplem o código ou mensagem de erro.</p>
                        </div>
                        <Dropzone onFilesChange={handleAddFiles} />
                        <PreviewImage 
                            subject={subject}
                            labelText='Anexos Inseridos' 
                            onFilesChange={handleFilesChange} 
                            files={uploadedFiles}
                        />
                    </div>

                    <div className='container-buttons-new-ticket'>
                        <div className='button-cancel-new-ticket'>
                            <CustomButton labelText='Cancelar' variant='primary' onClick={() => onCancel()} />
                        </div>
                        <div className='button-save-new-ticket'>
                            <CustomButton labelText='Criar Chamado' variant='primary' type="submit" />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default NewTicket;