import React from 'react';
import "./style.css";
import Sidebar from 'components/sidebar';
import Navbar from 'components/header';

function Dashboard() {
    return(
        <div className='container-main-dashboard'>
            <Sidebar />
            <Navbar />
            <div className='container-content-dashboard'>
                <h1>Tela de Dashboard em desenvolvimento</h1>
            </div>
        </div>
    );
}

export default Dashboard;