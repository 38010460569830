import { TicketAttachment } from "../types/ticket-attachment-types";
import { ApiResponse, GetByListTicketStatusIdRequest, GetTicketsRespostRequest, PagedResult, PostApiResponse, RespostTicketsRequest, UpdateTicketStatusRequest } from "../types/response/api-types";
import { Ticket } from "../types/ticket-types";
import AuthenticationService from "./login/auth-service";
import { TicketViewModel } from "../types/models/ticket-view-model";
import { TicketStatusCount } from "../types/ticket-status-count-types";
import { DetailsTicketViewModel } from "../types/models/details-ticket-view-model";
import { TicketCommentary } from "../types/ticket-commentary-types";
import { PriorityCount } from "../types/priority-count-types";
import Config from "config";

const API_URL = `${Config.API_URL}${Config.Endpoints.Ticket}`;

const TicketService = {
    CreateTicket: async(ticket: Ticket, ticketAttachments: TicketAttachment[]): Promise<PostApiResponse<boolean>> => { 
        try {
            const token = AuthenticationService.GetToken();
            const requestData = {
                Ticket: ticket, 
                TicketAttachments: ticketAttachments 
            };

            const response = await fetch(`${API_URL}/createticket`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`  
                },
                body: JSON.stringify(requestData),
            });

            const data: PostApiResponse<boolean> = await response.json();
            if (data.success) {
                return data;
            } else {
                return {
                    success: false,
                    message: "Falha ao criar um novo chamado.",
                    data: false
                };
            }
        } catch (error) {
            return {
                success: false,
                message: "Falha ao criar um novo chamado.",
                data: false
            };
        }
    },
    GetByListTicketStatusId: async (request: GetByListTicketStatusIdRequest): Promise<PostApiResponse<PagedResult<TicketViewModel>>> => {
        try {
            const token = AuthenticationService.GetToken();
            const response = await fetch(`${API_URL}/getpagedtickets`, {
                method: 'POST', 
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` 
                },
                body: JSON.stringify(request)
            });

            const data: PostApiResponse<PagedResult<TicketViewModel>> = await response.json();

            if (data.success) {
                return data;
            } else {
                return {
                    success: false,
                    message: "Falha ao obter os chamados.",
                    data: {} as  PagedResult<TicketViewModel>
                };
            }
        } catch (error) {
            return {
                success: false,
                message: "Falha ao obter os chamados.",
                data: {} as  PagedResult<TicketViewModel> 
            };
        }
    },
    GetCountsByAllTicketStatus: async (accountId: string,  profileId: string): Promise<ApiResponse<TicketStatusCount>> => {
        try {
            const token = AuthenticationService.GetToken();
            const response = await fetch(`${API_URL}/getcountsbyallticketstatus`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` 
                },
                body: JSON.stringify({AccountId: accountId, ProfileId: profileId})
            });

            const data: ApiResponse<TicketStatusCount> = await response.json();

            if (data.success) {
                return data;
            } else {
                return {
                    success: false,
                    message: "Falha ao obter contagem dos chamados.",
                    data: [] 
                };
            }
        } catch (error) {
            return {
                success: false,
                message: "Falha ao obter contagem dos chamados.",
                data: [] 
            };
        }
    },
    GetDetailsTicketByTicketId: async (ticketId: string): Promise<PostApiResponse<DetailsTicketViewModel>> => {
        try {
            const token = AuthenticationService.GetToken();
            const response = await fetch(`${API_URL}/getdetailsticketbyticketid`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` 
                },
                body: JSON.stringify({ TicketId: ticketId }),
            });
    
            const data: PostApiResponse<DetailsTicketViewModel> = await response.json();
                    
            if (data.success) {
                return data;
            } else {
                return {
                    success: false,
                    message: "Falha ao obter os detalhes do chamado.",
                    data: {} as DetailsTicketViewModel
                };
            }
        } catch (error) {
            return {
                success: false,
                message: "Falha ao obter os detalhes do chamado.",
                data: {} as DetailsTicketViewModel
            };
        }
    },
    UpdateTicketDetails: async(ticketId: string, ticketCommentaries: TicketCommentary[], ticketAttachments: TicketAttachment[]): Promise<PostApiResponse<boolean>> => { 
        try {
            const token = AuthenticationService.GetToken();
            const requestData = {
                TicketId: ticketId,
                TicketAttachments: ticketAttachments, 
                TicketCommentaries: ticketCommentaries 
            };

            const response = await fetch(`${API_URL}/updateticketDetails`, {
                method: 'PUT',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`  
                },
                body: JSON.stringify(requestData),
            });

            const data: PostApiResponse<boolean> = await response.json();

            if (data.success) {
                return data;
            } else {
                return {
                    success: false,
                    message: "Falha ao atualizar os detalhes do chamado.",
                    data: false
                };
            }
        } catch (error) {
            return {
                success: false,
                message: "Falha ao atualizar os detalhes do chamado.",
                data: false
            };
        }
    },
    GetCountsByAllPriorities: async (accountId: string, profileId: string): Promise<ApiResponse<PriorityCount>> => {
        try {
            const token = AuthenticationService.GetToken();
            const response = await fetch(`${API_URL}/getcountsbyallpriorities`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` 
                },
                body: JSON.stringify({AccountId: accountId, ProfileId: profileId})
            });

            const data: ApiResponse<PriorityCount> = await response.json();

            if (data.success) {
                return data;
            } else {
                return {
                    success: false,
                    message: "Falha ao obter contagem dos chamados.",
                    data: [] 
                };
            }
        } catch (error) {
            return {
                success: false,
                message: "Falha ao obter contagem dos chamados.",
                data: [] 
            };
        }
    },
    UpdateTicketStatus: async(request: UpdateTicketStatusRequest): Promise<PostApiResponse<boolean>> => { 
        try {
            const token = AuthenticationService.GetToken();

            const response = await fetch(`${API_URL}/updateticketstatus`, {
                method: 'PUT',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`  
                },
                body: JSON.stringify(request),
            });

            const data: PostApiResponse<boolean> = await response.json();

            if (data.success) {
                return data;
            } else {
                return {
                    success: false,
                    message: "Falha ao atualizar os detalhes do chamado.",
                    data: false
                };
            }
        } catch (error) {
            return {
                success: false,
                message: "Falha ao atualizar os detalhes do chamado.",
                data: false
            };
        }
    },
    GetTicketsRespost: async (request: GetTicketsRespostRequest): Promise<ApiResponse<TicketViewModel>> => {
        try {
            const token = AuthenticationService.GetToken();
            const response = await fetch(`${API_URL}/getticketsrespost`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` 
                },
                body: JSON.stringify(request)
            });

            const data: ApiResponse<TicketViewModel> = await response.json();
            if (data.success) {
                return data;
            } else {
                return {
                    success: false,
                    message: "Falha ao obter os chamados.",
                    data: [] 
                };
            }
        } catch (error) {
            return {
                success: false,
                message: "Falha ao obter os chamados.",
                data: [] 
            };
        }
    },
    RespostTicketst: async(request: RespostTicketsRequest): Promise<PostApiResponse<boolean>> => { 
        try {
            const token = AuthenticationService.GetToken();

            const response = await fetch(`${API_URL}/respostticketst`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`  
                },
                body: JSON.stringify(request),
            });

            const data: PostApiResponse<boolean> = await response.json();

            if (data.success) {
                return data;
            } else {
                return {
                    success: false,
                    message: "Falha ao repassar os chamados.",
                    data: false
                };
            }
        } catch (error) {
            return {
                success: false,
                message: "Falha ao repassar os chamados.",
                data: false
            };
        }
    }
};

export default TicketService;
