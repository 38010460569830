import React, { useEffect, useState } from 'react';
import "./style.css";
import IconArrow from '../../assets/icon_arrow_left.png';
import { TicketStatus } from '../../types/enum/ticket-status-enum-types';
import TicketService from 'services/ticket-service';
import { TicketStatusCount } from '../../types/ticket-status-count-types';
import AuthenticationService from 'services/login/auth-service';
import { DecodeToken } from 'utils/decode-token';
import { TokenClaims } from '../../types/token-claims';
import { PriorityCount } from '../../types/priority-count-types';
import { NotificationViewModel } from '../../types/models/notification-view-model';
import { ApiResponse } from '../../types/response/api-types';
import NotificationService from 'services/notification-service';
import { useNavigate } from 'react-router-dom';
import { AccessProfileEnum } from '../../types/enum/access-profile-enum';

interface MenuProps {
    onUpdateListTicketStatus: (status: TicketStatus[]) => void;
    onUpdatePriority: (priority: PriorityCount | undefined,) => void;
}

function Menu({ onUpdateListTicketStatus, onUpdatePriority }: MenuProps) {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [selectedOption, setSelectedOption] = useState(0);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        listTicketStatus: [] as TicketStatus[],
        statusCounts: [] as TicketStatusCount[],
        prioritiesCounts: [] as PriorityCount[],
        claims: null as TokenClaims | null,
        notifications: [] as NotificationViewModel[]
    });
    const navigate = useNavigate();

    useEffect(() => {
        if (!loading) {
            const initializeData = async () => {
                const token = AuthenticationService.GetToken();
                if (token) {
                    const decoded = await DecodeToken(token);
                    if (decoded) {
                        setData(prev => ({ ...prev, claims: decoded }));
                        await fetchTicketStatusCounts(decoded.accountid, decoded.profileid);
                        if (decoded.profileid.toLowerCase() !== AccessProfileEnum.User.toLowerCase()) {
                            await fetchPrioritiesCounts(decoded.accountid, decoded.profileid);
                            await fetchNotifications(decoded.accountid);
                        }
                    }
                }
                handleSelectOption(selectedOption);
            };
            initializeData();
        }
    }, []);

    const fetchTicketStatusCounts = async (accountId: string, profileId: string) => {
        try {
            setLoading(true); 
            const response = await TicketService.GetCountsByAllTicketStatus(accountId, profileId);
            if (response.success) {
                setData(prev => ({ ...prev, statusCounts: response.data }));
            } else {
                console.error(response.message);
            }
        } catch (error) {
            console.error('Error fetching ticket status counts', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchPrioritiesCounts = async (accountId: string, profileId: string) => {
        try {
            setLoading(true);
            const response = await TicketService.GetCountsByAllPriorities(accountId, profileId);
            if (response.success) {
                setData(prev => ({ ...prev, prioritiesCounts: response.data }));
            } else {
                console.error(response.message);
            }
        } catch (error) {
            console.error('Error fetching priorities counts', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchNotifications = async (accountId: string) => {
        try {
            setLoading(true);
            const response: ApiResponse<NotificationViewModel> = await NotificationService.GetUpdatesByAccountIdA(accountId);
            if (response.success) {
                setData(prev => ({ ...prev, notifications: response.data }));
            } else {
                console.error(response.message);
            }
        } catch (error) {
            console.error('Error fetching notifications', error);
        } finally {
            setLoading(false);
        }
    };

    const handleItemClick = async (ticketId: string, notificationId: string) => {
        if (!loading) {
            const result = await NotificationService.UpdateReadNotificationsByListId([notificationId]);
            if (result.success && data.claims) {
                await fetchNotifications(data.claims.accountid);
            }

            if (data.claims?.profileid?.toLowerCase() === AccessProfileEnum.Operator.toLowerCase()) {
                navigate(`/Manage?TicketId=${ticketId}`);
            } else {
                navigate(`/Workspace?TicketId=${ticketId}`);
            }
        }
    };

    const toggleMenu = () => {
        setIsCollapsed(prev => !prev);
    };

    const handleSelectOption = async (menuOption: number, priorityCount?: PriorityCount) => {
        if (!loading) {
            setLoading(true);
            setSelectedOption(menuOption);

            let listStatus: TicketStatus[] = [];
            switch (menuOption) {
                case 0:
                    listStatus = Object.values(TicketStatus);
                    break;
                case 1:
                    listStatus = [TicketStatus.Registrado];
                    break;
                case 2:
                    listStatus = [TicketStatus.EmAndamento, TicketStatus.EsperandoAnexos, TicketStatus.Reaberto];
                    break;
                case 3:
                    listStatus = [TicketStatus.Encerrado];
                    break;
                default:
                    break;
            }

            setData(prev => ({ ...prev, listTicketStatus: listStatus }));
            onUpdateListTicketStatus(listStatus);
            onUpdatePriority(priorityCount);
            setLoading(false);
        }
    };

    const menuOptions = [
        { id: 1, label: 'Abertos', listStatus: [TicketStatus.Registrado] },
        { id: 2, label: 'Em Atendimento', listStatus: [TicketStatus.EmAndamento, TicketStatus.EsperandoAnexos, TicketStatus.Reaberto] },
        { id: 3, label: 'Encerrados', listStatus: [TicketStatus.Encerrado] },
    ];

    const getStatusCount = (listStatus: TicketStatus[]) => {
        return listStatus.reduce((total, statusId) => {
            const status = data.statusCounts.find(s => s.id.toLowerCase() === statusId.toLowerCase());
            return total + (status ? status.count : 0);
        }, 0);
    };

    return (
        <div className={`container-menu ${isCollapsed ? 'collapsed' : ''}`}>
            <div className='container-main-menu'>         
                <div className='container-menu-views'>
                    <div className='title-menu'>
                        <p>Visualizações</p>
                    </div>
                    <div className='menu-vector'></div>

                    <div className='container-items-link'>
                        <ul className='list-items'>
                            <li
                                className={`item ${selectedOption === 0 ? 'selected' : ''}`}
                                onClick={() => handleSelectOption(0)}
                            >
                                <span>Todos os Chamados</span>
                                <span className='item-quantity'>
                                    {data.statusCounts.reduce((acc, curr) => acc + curr.count, 0)}
                                </span>
                            </li>
                            {menuOptions.map(option => (
                                <li
                                    key={option.id}
                                    className={`item ${selectedOption === option.id ? 'selected' : ''}`}
                                    onClick={() => handleSelectOption(option.id)}
                                >
                                    <span>Chamados: <strong>{option.label}</strong></span>
                                    <span className='item-quantity'>{getStatusCount(option.listStatus)}</span>
                                </li>
                            ))}
                            {data.claims?.profileid?.toLowerCase() !== AccessProfileEnum.User.toLowerCase() &&
                                data.prioritiesCounts.map((option, index) => (
                                    <li
                                        key={option.id}
                                        className={`item ${selectedOption === (index + 4) ? 'selected' : ''}`}
                                        onClick={() => handleSelectOption(index + 4, option)}
                                    >
                                        <span>Prioridade: <strong>{option.name}</strong></span>
                                        <span className='item-quantity'>{option.count}</span>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    {data.claims?.profileid?.toLowerCase() !== AccessProfileEnum.User.toLowerCase() && (
                        <div className='container-updates-notifications'>
                            <div className='title-menu'>
                                <p>Atualizações</p>
                            </div>
                            <div className='menu-vector'></div>
                            <ul className='list-items'>
                                {data.notifications.length === 0 ? (
                                    <li className='notification-item no-notifications'>
                                        <span>Não há atualizações.</span>
                                        <div className='no-notification-dot'></div>
                                    </li>
                                ) : (
                                    data.notifications.map((option) => (
                                        <li
                                            className='notification-item'
                                            key={option.id}
                                            onClick={() => handleItemClick(option.ticketId, option.id)}
                                        >
                                            <span>{option.message}</span>
                                            <div className='notification-dot'></div>
                                        </li>
                                    ))
                                )}
                            </ul>
                        </div>
                    )}    
                </div>
                <div className={`menu-icon ${isCollapsed ? 'rotated' : ''}`} onClick={toggleMenu}>
                    <img src={IconArrow} alt="Ícone de expandir menu" />
                </div>
            </div>
        </div>
    );
}

export default Menu;