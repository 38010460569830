import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import "../../styles/global.css";
import Sidebar from 'components/sidebar';
import CustomButton from 'components/custom-button';
import InputWithLabel from 'components/custom-input-text-label';
import TextareaWithLabel from 'components/custom-textarea-label';
import { Module } from '../../types/module-types';
import ModuleService from 'services/module-service';
import { Checkbox } from 'antd';
import Navbar from 'components/header';
import CustomNotification from 'components/custom-notification';
import { Box, Spinner } from '@chakra-ui/react';

function CreateOrEditModule() {
    const [formState, setFormState] = useState({
        module: {
            id: '00000000-0000-0000-0000-000000000000',
            name: '',
            description: '',
            status: true,
        },
        errors: { name: false, description: false },
    });
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation(); 

    useEffect(() => {
        if (location.state?.rowData) {
            const module = location.state.rowData as Module;
            setFormState(prev => ({ ...prev, module }));
            setIsEditing(true); 
        }
    }, [location.state]);

    const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormState(prev => ({ ...prev, module: { ...prev.module, [name]: value }, errors: { ...prev.errors, [name]: false } }));
    }, []);

    const validateInputs = () => {
        const { module } = formState;
        const newErrors = { name: !module.name.trim(), description: !module.description.trim() };
        return newErrors;
    };

    const showNotification = (title: string, description: string, type: 'success' | 'error' | 'warning') => {
        CustomNotification({
            title,
            description,
            notificationType: type,
            placement: 'top-right',
        });
    };

    const handleBack = () => {
        navigate(-1);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const newErrors = validateInputs();
        
        if (newErrors.name || newErrors.description) {
            setFormState(prev => ({ ...prev, errors: newErrors }));
            if (newErrors.name) showNotification('Atenção!', 'O campo Nome é obrigatório.', 'warning');
            if (newErrors.description) showNotification('Atenção!', 'O campo Descrição é obrigatório.', 'warning');
            return;
        }
        
        setLoading(true);
        try {
            const result = isEditing
                ? await ModuleService.UpdateModule(formState.module)
                : await ModuleService.CreateModule(formState.module);

            if (result.success) {
                showNotification('Ação concluída com sucesso!', isEditing ? 'Módulo atualizado com sucesso!' : 'Módulo criado com sucesso!', 'success');
                navigate("/Workspace/Modules");
            } else {
                throw new Error(result.message);
            }
        } catch (error) {
            showNotification('Algo deu errado!', 'Ocorreu um erro ao processar a solicitação.', 'error');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='container-crud'>
            <Sidebar />
            <Navbar />
            <div className='container-content-crud'>
                <div className='container-header-crud'>
                    <span>{isEditing ? "Editando Módulo" : "Criando Novo Módulo"}</span>
                    <div className='button-header-crud'>
                        <CustomButton variant='secondary' labelText='Voltar' onClick={handleBack} />
                    </div>
                </div>

                <div className='container-main-crud'>
                    {loading && (
                        <Box
                            position="absolute"
                            top="0"
                            left="0"
                            width="100%"
                            height="100%"
                            bg="rgba(255, 255, 255, 0.8)"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            zIndex="10"
                        >
                            <Spinner size="xl" />
                        </Box>
                    )} 
                    <div className='container-title-crud'>
                        <span>Formulário de Módulo</span>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className='container-fields-crud'>
                            <div className='container-field-checkbox-crud'>
                                <Checkbox 
                                    checked={formState.module.status} 
                                    onChange={(e) => setFormState(prev => ({ ...prev, module: { ...prev.module, status: e.target.checked } }))} 
                                    className='container-custom-checkbox-input' 
                                />
                                <label>Ativo</label>
                            </div>

                            <div className='container-field-text-crud'>
                                <div className='field-text-name-crud'> 
                                    <InputWithLabel 
                                        label='Nome' 
                                        placeholder='Nome'
                                        name="name"
                                        error={formState.errors.name} 
                                        maxLength={100}
                                        value={formState.module.name}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>

                            <div className='container-textarea-crud'>
                                <TextareaWithLabel 
                                    label='Descrição' 
                                    placeholder='Escreva a Descrição do Módulo' 
                                    name="description"
                                    error={formState.errors.description}
                                    maxLength={250}
                                    value={formState.module.description}
                                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                        setFormState(prev => ({ ...prev, module: { ...prev.module, description: e.target.value }, errors: { ...prev.errors, description: false } }));
                                    }}
                                />
                            </div>
                            <div>
                                <CustomButton type="submit" variant='primary' labelText={isEditing ? 'Atualizar' : 'Confirmar'} />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default CreateOrEditModule;
