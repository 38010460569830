import React, { useCallback, useEffect, useState } from 'react';
import "./style.css";
import { DetailsTicketViewModel } from '../../types/models/details-ticket-view-model';
import { TokenClaims } from '../../types/token-claims';
import { TicketCommentary } from '../../types/ticket-commentary-types';
import AuthenticationService from 'services/login/auth-service';
import { DecodeToken } from 'utils/decode-token';
import TicketService from 'services/ticket-service';
import { TicketStatus } from '../../types/enum/ticket-status-enum-types';
import { Box, Spinner } from '@chakra-ui/react';
import InputWithLabel from 'components/custom-input-text-label';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale/pt-BR';
import TextareaWithLabel from 'components/custom-textarea-label';
import PreviewImage from 'components/preview-image';
import CustomButton from 'components/custom-button';
import { PriorityViewModel } from '../../types/models/priority-view-model';
import Select from 'antd/es/select';
import PriorityService from 'services/priority-service';
import { UpdateTicketStatusRequest } from '../../types/response/api-types';
import { PriorityEnum } from '../../types/enum/priority-enum-types';
import Dropzone from 'components/dropzone';
import { TicketAttachment } from '../../types/ticket-attachment-types';
import { AccessProfileEnum } from '../../types/enum/access-profile-enum';
import CustomNotification from 'components/custom-notification';
import { Rate } from 'antd';
import { formatClassTicketStatus } from 'utils/ticket-status-helpers';
import moment from 'moment';

interface TicketManageProps {
    ticketId: string;
    onTicketUpdated: () => void;
    onCancel: () => void;
    setLoading: (loading: boolean) => void;
}

function TicketManage ({ ticketId, onTicketUpdated, onCancel, setLoading  }: TicketManageProps) {
    const [ticketDetails, setTicketDetails] = useState<DetailsTicketViewModel>({
        ticketId: '',
        requesterId: '',
        operatorId: '',
        ticketStatusId: '',
        priorityId: '',
        subject: '',
        detailedDescription: '',
        ticketNumber: '',
        requesterName: '',
        priorityName: '',
        priorityHexadecimal: '',
        moduleName: '',
        ticketTypeName: '',
        ticketStatusName: '',
        requestDate: null,
        closeDate: null,
        ticketCommentaries: [],
        ticketAttachments: []
    });   
    const [loading, setLocalLoading] = useState(false);
    const [claims, setClaims] = useState<TokenClaims>({} as TokenClaims);
    const [canIsEditing, setCanIsEditing] = useState(false);
    const [files, setFiles] = useState<File[]>([]);
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
    const [ticketCommentaries, setTicketCommentaries] = useState<TicketCommentary[]>([]);
    const [commentaries, setCommentaries] = useState<any[]>([]);
    const [newComment, setNewComment] = useState<string>('');
    const [priorities, setPriorities] = useState<PriorityViewModel[]>([]);
    const { Option } = Select;

    useEffect(() => {
        const token = AuthenticationService.GetToken();

        if (token) {
            const decoded = DecodeToken(token);
            if (decoded) {
                setClaims(decoded);
            }
        }
    }, []);
    

    useEffect(() => {
        const fetchTicketDetails = async () => {            
            if (ticketId) {
                setLocalLoading(true);             
        
                try {
                    const response = await TicketService.GetDetailsTicketByTicketId(ticketId);
        
                    if (response.success) {
                        const operatorId = response.data?.operatorId;
                        const accountId = claims?.accountid;
        
                        if (operatorId && accountId) {
                            const canIsEdit = ((response.data?.operatorId.toLowerCase() === claims?.accountid.toLowerCase() 
                            || claims.profileid.toLowerCase() === AccessProfileEnum.Administrator.toLowerCase()) && response.data?.ticketStatusId.toLowerCase() !== TicketStatus.Encerrado.toLowerCase());
                            setCanIsEditing(canIsEdit);
                        }
        
                        setTicketDetails(response.data);
        
                        const combinedList = [
                            ...response.data.ticketCommentaries.map(commentary => ({
                                type: 'commentary',
                                id: commentary.id,
                                authorName: commentary.authorName,
                                createdOn: new Date(commentary.createdOn),
                                observation: commentary.observation,
                            })),
                            ...response.data.ticketAttachments.map(attachment => ({
                                type: 'attachment',
                                id: attachment.id,
                                authorName: attachment.authorName,
                                fileName: attachment.fileName,
                                createdOn: new Date(attachment.createdOn),
                            }))
                        ].sort((a, b) => b.createdOn.getTime() - a.createdOn.getTime());
        
                        setCommentaries(combinedList);
                    } else {
                        CustomNotification({
                            title: 'Algo deu errado!',
                            description: response.message || 'Erro ao processar a requisição.',
                            notificationType: 'error',
                            placement: 'top-right'
                        });
                    }
                } catch (error) {
                    CustomNotification({
                        title: 'Erro ao buscar detalhes do ticket!',
                        description: 'Erro inesperado ao processar a requisição.',
                        notificationType: 'error',
                        placement: 'top-right'
                    });
                } finally {
                    setLocalLoading(false);
                }
            }
        };
        
        fetchTicketDetails();
    }, [ticketId, claims]);

    const fetchPriorities = useCallback(async () => {
        try {
            const response = await PriorityService.GetAllPriorities();
    
            if (response.success && response.data) {
                setPriorities(response.data);
            } else {
                console.error(response.message);
                CustomNotification({
                    title: 'Erro ao buscar prioridades',
                    description: 'Erro inesperado ao processar a requisição.',
                    notificationType: 'error',
                    placement: 'top-right',
                });
            }
        } catch (error) {
            CustomNotification({
                title: 'Erro ao buscar prioridades',
                description: 'Erro inesperado ao processar a requisição.',
                notificationType: 'error',
                placement: 'top-right',
            });
        }
    }, []);

    useEffect(() => {
        fetchPriorities();
    }, [fetchPriorities]);
 
    const formatTitle = () => {
        return ((ticketDetails?.ticketStatusId.toLowerCase() === TicketStatus.Registrado.toLowerCase() || ticketDetails.ticketStatusName.toLowerCase() === TicketStatus.Registrado.toLocaleLowerCase())) ? "Chamado Aberto" : "Acompanhamento";
    };

    const formatDate = (date: Date): string => {
        return format(date, "dd/MM/yyyy ' - ' HH'h'mm", { locale: ptBR });
    };

    const handleFilesChange = (files: File[]) => {
        setUploadedFiles(files); 
    };

    const handleAddFiles = (files: File[]) => {
        setUploadedFiles([...files, ...uploadedFiles]); 
    };

    const handlePriorityChange = useCallback((value: string) => {
        setTicketDetails(prev => ({ ...prev, priorityId: value }));
    }, []);

    const handleAddCommentary = () => {
        if (newComment.trim()) {
            const newTicketCommentary: TicketCommentary = {
                id: Math.random().toString(36).substr(2, 8),
                ticketId: ticketId,
                observation: newComment,
                authorId: claims.accountid,
                authorName: claims.name,
                createdOn: new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000)
            };

            setTicketCommentaries([...ticketCommentaries, newTicketCommentary]);
            setNewComment('');
        }
    };

    const handleTakePossession = async () => {
        const request: UpdateTicketStatusRequest = {
            AccountId: claims.accountid,
            TicketId: ticketDetails.ticketId,
            PriorityId: ticketDetails.priorityId,
            TicketStatusId: TicketStatus.EmAndamento
        };
    
        if (request.PriorityId && request.PriorityId.toLowerCase() !== PriorityEnum.NaoIniciado.toLowerCase()) {      
            setLoading(true);
            
            try {
                const response = await TicketService.UpdateTicketStatus(request);
                if (response.success) {
                    CustomNotification({
                        title: 'Ação concluída com sucesso!',
                        description: 'Chamado atualizado com sucesso.',
                        notificationType: 'success',
                        placement: 'top-right',
                    });
                    onTicketUpdated();
                } else {
                    console.error(response.message);
                    CustomNotification({
                        title: 'Algo deu errado!',
                        description: 'Erro inesperado ao processar a requisição.',
                        notificationType: 'error',
                        placement: 'top-right',
                    });
                }
            } catch (error) {
                CustomNotification({
                    title: 'Algo deu errado!',
                    description: 'Erro inesperado ao processar a requisição.',
                    notificationType: 'error',
                    placement: 'top-right',
                });
            } finally {
                setLoading(false);
            }
        } else {
            CustomNotification({
                title: 'Atenção!',
                description: 'A prioridade selecionada não é permitida para a posse do chamado. Selecione outra prioridade',
                notificationType: 'warning',
                placement: 'top-right',
            });
        }
    };
    

    const handleCloseTicket = async () => {
        const request: UpdateTicketStatusRequest = {
            AccountId: claims.accountid,
            TicketId: ticketDetails.ticketId,
            TicketStatusId: TicketStatus.Encerrado
        };
    
        setLoading(true);
        
        try {
            const response = await TicketService.UpdateTicketStatus(request);
            if (response.success) {
                onTicketUpdated();
                CustomNotification({
                    title: 'Ação concluída com sucesso!',
                    description: 'Chamado encerrado com sucesso.',
                    notificationType: 'success',
                    placement: 'top-right',
                });
            } else {
                console.error(response.message);
                CustomNotification({
                    title: 'Algo deu errado!',
                    description: response.message || 'Erro inesperado ao processar a requisição.',
                    notificationType: 'error',
                    placement: 'top-right',
                });
            }
        } catch (error) {
            CustomNotification({
                title: 'Algo deu errado!',
                description: 'Erro inesperado ao processar a requisição.',
                notificationType: 'error',
                placement: 'top-right',
            });
        } finally {
            setLoading(false);
        }
    };
    
    const handleRequestNewAttachments = async () => {
        const request: UpdateTicketStatusRequest = {
            AccountId: claims.accountid,
            TicketId: ticketDetails.ticketId,
            TicketStatusId: TicketStatus.EsperandoAnexos
        };
    
        setLoading(true);
        
        try {
            const response = await TicketService.UpdateTicketStatus(request);
            if (response.success) {
                onTicketUpdated();
                CustomNotification({
                    title: 'Ação concluída com sucesso!',
                    description: 'Solicitação de novos anexos foi feita com sucesso.',
                    notificationType: 'success',
                    placement: 'top-right',
                });
            } else {
                CustomNotification({
                    title: 'Algo deu errado!',
                    description: response.message || 'Erro inesperado ao processar a requisição.',
                    notificationType: 'error',
                    placement: 'top-right',
                });
            }
        } catch (error) {
            CustomNotification({
                title: 'Algo deu errado!',
                description: 'Erro inesperado ao processar a requisição.',
                notificationType: 'error',
                placement: 'top-right',
            });
        } finally {
            setLoading(false);
        }
    };
    

    const fileToBase64 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onload = () => {
                if (fileReader.result) {
                    const base64 = (fileReader.result as string).split(',')[1];
                    resolve(base64);
                } else {
                    reject(new Error('Failed to read file content'));
                }
            };
            fileReader.onerror = () => {
                reject(new Error('Error reading file'));
            };
            fileReader.readAsDataURL(file);
        });
    };

    const transformFilesToTicketAttachment = async (files: File[]): Promise<TicketAttachment[]> => {
        const ticketAttachments = await Promise.all(files.map(async (file) => {
            const fileContent = await fileToBase64(file);
            return {
                id: '00000000-0000-0000-0000-000000000000',
                ticketId: ticketId,
                authorId: claims.accountid,
                fileName: file.name,
                filePath: '',
                fileType: file.type,
                fileContent: fileContent,
                createdOn: new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000)
            };
        }));

        return ticketAttachments;
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
    
        setLoading(true);
        setLocalLoading(true);
    
        try {
            const ticketAttachments = await transformFilesToTicketAttachment(uploadedFiles);
    
            const commentaries = ticketCommentaries.map(x => ({
                ...x,
                id: '00000000-0000-0000-0000-000000000000' 
            }));
            
            if(commentaries.length === 0 && ticketAttachments.length === 0){
                CustomNotification({
                    title: 'Atenção!',
                    description: 'É necessário fazer alguma alteração para salvar.',
                    notificationType: 'warning',
                    placement: 'top-right'
                });

                return;
            }

            const response = await TicketService.UpdateTicketDetails(ticketId, commentaries, ticketAttachments);
    
            if (response.success) {
                onTicketUpdated();
                CustomNotification({
                    title: 'Ação concluída com sucesso!',
                    description: 'Detalhes do chamados atualizados com sucesso.',
                    notificationType: 'success',
                    placement: 'top-right',
                });
            } else {
                console.error(response.message);
                CustomNotification({
                    title: 'Algo deu errado!',
                    description: response.message || 'Erro inesperado ao processar a requisição.',
                    notificationType: 'error',
                    placement: 'top-right',
                });
            }
        } catch (error) {
            CustomNotification({
                title: 'Algo deu errado!',
                description: 'Erro inesperado ao processar a requisição.',
                notificationType: 'error',
                placement: 'top-right',
            });
        } finally {
            setLoading(false);
            setLocalLoading(false);
        }
    };

    const formatTicketStatus = () => {
        return formatClassTicketStatus[ticketDetails.ticketStatusId.toUpperCase() as TicketStatus] || formatClassTicketStatus[TicketStatus.Registrado];
    };
    
    return(
        <div className='container-main-details-manage'>
            {(ticketDetails?.ticketFeedback && claims.profileid.toLowerCase() === AccessProfileEnum.Administrator.toLowerCase()) && (
                <div className='container-ticket-close'>
                    <div className='container-ticket-feedback'>
                        <div className='container-ticket-feedback-title'>
                            <span className='ticket-feedback-title'>Avaliação Pós-Chamado</span>
                            <span className='ticket-feedback-sub-title'>Esse chamado foi encerrado, avaliação do atendimento do operador!</span>
                        </div>

                        <div className='container-ticket-feedback-rate'>
                            <Rate className='ticket-feedback-rate'  
                                value={ticketDetails.ticketFeedback?.scoreRate}
                                disabled={true}
                            />
                        </div>

                        <div className='textarea-ticket-feedback'>
                            <TextareaWithLabel 
                                placeholder='Escreva sobre como foi o seu atendimento durante o chamado!' 
                                label='' 
                                disabled={true}
                                value={ticketDetails.ticketFeedback?.commentary}
                            />
                        </div> 
                    </div>
                </div>
            )}
            <div className='container-title-details-manage'>
                <span>{formatTitle()}</span>
            </div>
            <div className='container-sub-title-details-manage'>
                <span>Assunto do Chamado</span>
            </div>

            <div className='container-form-details-manage'>
                {loading && (
                    <Box
                        position="absolute"
                        top="0"
                        left="0"
                        width="100%"
                        height="100%"
                        bg="rgba(255, 255, 255, 0.8)"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        zIndex="10"
                    >
                        <Spinner size="xl" />
                    </Box>
                )}

                <form onSubmit={handleSubmit}>
                    <div className='container-fields-details-manage'>
                        <div>
                            <InputWithLabel value={ticketDetails.ticketNumber} disabled={true} label='ID' />
                        </div>
                        <div>
                            <InputWithLabel value={ticketDetails.requesterName} disabled={true} label='Solicitante' />
                        </div>
                        <div>
                            <InputWithLabel value={ticketDetails.requestDate ? formatDate(new Date(ticketDetails.requestDate)) : '--/--/-- - 00h00'} disabled={true} label='Data/Hora da Solicitação' />
                        </div>
                        <div>
                            <InputWithLabel value={ticketDetails.closeDate ? formatDate(new Date(ticketDetails.closeDate)) : '--/--/-- - 00h00'} disabled={true} label='Data/Hora do Encerramento' />
                        </div>
                    </div>

                    <div className='container-info-details-manage'>
                        <div className='container-priorities-manage'>
                            <label className='container-input-label'>Prioridade</label>
                            <Select
                                value={ticketDetails.priorityId}
                                onChange={handlePriorityChange}
                                disabled={ticketDetails?.ticketStatusId?.toLowerCase() !== TicketStatus.Registrado.toLowerCase()}
                                getPopupContainer={trigger => trigger.parentNode}
                            >
                                {priorities.map(option => (
                                    <Option key={option.id} value={option.id}>
                                        <div className='container-tag-ticket-details'  style={{ backgroundColor: option.hexadecimal }}>
                                                {option.name}
                                        </div>
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        <div>
                            <InputWithLabel value={ticketDetails.moduleName} disabled={true} label='Módulo' />
                        </div>
                        <div>
                            <InputWithLabel value={ticketDetails.ticketTypeName} disabled={true} label='Tipo' />
                        </div>
                        <div>
                            <label className='custom-label'>Status</label>
                            <div className='container-ticket-status'>
                                <span className="text-ticket-status">{ticketDetails.ticketStatusName}</span>
                                <div className={`dot-ticket-status ${formatTicketStatus()}`}></div>
                            </div>
                        </div>
                    </div>

                    <div className='container-textarea-details-manage'>
                        <div className='container-textarea-subject'>
                            <TextareaWithLabel 
                                placeholder='Nomeie como se fosse o título do chamado' 
                                label='Assunto' 
                                value={ticketDetails.subject}
                                disabled={true}
                            />
                        </div>
                        <div className='container-textarea-description-manage'>
                            <TextareaWithLabel 
                                placeholder='Descrição do seu problema relatando ações e processos que levaram ao erro.' 
                                label='Descrição Detalhada' 
                                value={ticketDetails.detailedDescription}
                                disabled={true}
                            />
                        </div>
                        {(ticketDetails?.ticketStatusId?.toLowerCase() !== TicketStatus.Registrado.toLowerCase() && canIsEditing) && (
                            <div className='container-textarea-comment'>
                                <TextareaWithLabel 
                                    placeholder='Comentário' 
                                    label='Adicionar e Responder Observações' 
                                    value={newComment}
                                    onChange={(e) => setNewComment(e.target.value)}
                                />
                                <div className='button-observation-ticket-details'>
                                    <CustomButton labelText='Adicionar Observação' variant='primary' onClick={handleAddCommentary} />
                                </div>
                            </div>
                        )}
                    </div>
                    
                    {ticketDetails?.ticketStatusId.toLowerCase() === TicketStatus.Registrado.toLowerCase() ? (
                        <div>
                            <div className='container-attachments-details-manage'>
                                <PreviewImage 
                                    subject={ticketDetails.subject}
                                    labelText='Anexos Inseridos'
                                    onFilesChange={handleFilesChange} 
                                    existingAttachments={ticketDetails.ticketAttachments}
                                    files={files}
                                    />
                            </div>

                            <div className='container-take-ticket-manage'>
                                <div className='button-take-ticket-manage'>
                                    <CustomButton labelText='Tomar posse do Chamado' variant='primary' onClick={handleTakePossession} />
                                </div>

                                <div className='container-description-take-ticket'>
                                    <span className='description-take-ticket-manage'>O Operador deve definir um nível de prioridade antes de tomar a posse do chamado. O status do chamado será <br/> mudado automaticamente para <span className='description-take-ticket-bold'>“Em Andamento”</span></span>
                                </div>

                                <div className='button-cancel-details-manage'>
                                    <CustomButton labelText='Cancelar' variant='primary' onClick={() => onCancel()} />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className='container-comments-details'>
                                <div className='container-comments-title'>
                                    <span>Alterações e Observações</span>
                                </div>
                   
                                <div className='container-content-comments'>
                                    {ticketCommentaries.slice().sort((a, b) => b.createdOn.getTime() - a.createdOn.getTime()).map((commentary) => (
                                        <ul key={commentary.id} className='container-comment-title'>
                                            <li>
                                            {`${commentary.authorName} - ${moment(commentary.createdOn).add(3, 'hours').format('HH[h]mm')}`}
                                            <ul className='container-comment-description'>
                                                <li>
                                                    {commentary.observation}
                                                </li>
                                            </ul>
                                            </li>
                                        </ul>
                                    ))}

                                    {commentaries.map(item => (
                                        <ul key={item.id} className='container-comment-title'>
                                            <li>
                                                {item.type === 'commentary' ? (
                                                    <>
                                                        {`${item.authorName} - ${formatDate(item.createdOn)}`}
                                                        <ul className='container-comment-description'>
                                                            <li>{item.observation}</li>
                                                        </ul>
                                                    </>
                                                ) : (
                                                    <>
                                                        {`${item.authorName} - ${formatDate(item.createdOn)}`}
                                                        <ul className='container-comment-description'>
                                                            <li>{`Adicionou um anexo`} <span className='comment-attachment-file-name'>{ item.fileName}</span></li>
                                                        </ul>
                                                    </>
                                                )}
                                            </li>
                                        </ul>
                                    ))}

                                    <ul className='container-comment-title'>
                                        <li>
                                            {`${ticketDetails.requesterName} - ${ticketDetails.requestDate ? formatDate(ticketDetails.requestDate) : '--/--/-- - 00h00'}`}
                                            <ul className='container-comment-description'>
                                                <li>
                                                    <span>
                                                        Criou o Chamado 
                                                        {ticketDetails.ticketAttachments?.length === 1 ? (
                                                            <span> e Adicionou um anexo <span className='comment-attachment-file-name'>{ticketDetails.ticketAttachments[0].fileName}</span></span>
                                                        ) : ticketDetails.ticketAttachments?.length > 1 ? (
                                                            <span>e Adicionou alguns anexos</span>
                                                        ) : null}
                                                    </span>                     
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className='container-attachments-details'>
                                {(canIsEditing) && (
                                    <div>
                                        <div className='container-attachments-title-manage'>
                                            <span>Anexos</span>
                                            <p>Insira os anexos que demonstram o problema, comtemplem o código ou mensagem de erro.</p>
                                        </div>
                                        <Dropzone onFilesChange={handleAddFiles} />
                                        <PreviewImage 
                                            subject={ticketDetails.subject}
                                            labelText='Anexos Inseridos' 
                                            onFilesChange={handleFilesChange} 
                                            files={uploadedFiles}
                                        />
                                    </div>
                                )}

                                <PreviewImage 
                                    subject={ticketDetails.subject}
                                    subtitle='Veja os anexos inseridos anteriormente:'
                                    onFilesChange={handleFilesChange} 
                                    existingAttachments={ticketDetails.ticketAttachments}
                                    files={files}
                                />
                            </div>
                            {(canIsEditing) && (
                                <div className='container-request-new-attachments'>
                                    <div className='button-request-new-attachments'>
                                        <CustomButton labelText='Solicitar novos Anexos' variant='primary' onClick={handleRequestNewAttachments} />
                                    </div>
                                    <span className='description-take-ticket-manage'>*Tenha em mente que esse botão muda o Status <br/> automaticamente para <span className='description-take-ticket-bold'>“Aguardando Anexos”.</span></span>
                                </div>
                            )}

                            {(canIsEditing) && (
                                <div className='container-buttons-details-manage'>
                                    <div className='button-close-ticket'>
                                        <CustomButton labelText='Concluir Chamado' variant='primary' onClick={handleCloseTicket} />
                                    </div>
                                    <div className='container-buttons-action-manage'>
                                        <div className='button-cancel-action-manage'>
                                            <CustomButton labelText='Cancelar' variant='primary' onClick={() => onCancel()} />
                                        </div>
                                        <div className='button-confirm-details-manage'>
                                            <CustomButton labelText='Confirmar' variant='primary' type="submit" />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
}

export default TicketManage;