import { Module } from '../../types/module-types';
import "../../styles/global.css";
import { Checkbox, Select } from 'antd';
import CustomButton from 'components/custom-button';
import InputWithLabel from 'components/custom-input-text-label';
import Navbar from 'components/header';
import Sidebar from 'components/sidebar';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ModuleService from 'services/module-service';
import ProfileTypeService from 'services/profile-type-service';
import { ProfileType } from '../../types/profile-type-types';
import { AccountViewModel } from '../../types/models/account-view-model';
import { AccessProfileViewModel } from '../../types/models/access-profile-view-model';
import AccessProfileService from 'services/access-profile-service';
import { AccessProfileEnum } from '../../types/enum/access-profile-enum';
import AccountService from 'services/account-service';
import AuthenticationService from 'services/login/auth-service';
import { DecodeToken } from 'utils/decode-token';
import { TokenClaims } from '../../types/token-claims';
import { Box, Spinner } from '@chakra-ui/react';
import CustomNotification from 'components/custom-notification';

function CreateOrEditUser() {
    const [currentUser, setCurrentUser] = useState<AccountViewModel>({
        id: '00000000-0000-0000-0000-000000000000',
        accessProfileId: '',
        passwordHash: '',
        profileTypeId: '',
        accessProfileName: '',
        name: '',
        email: '',
        status: true,
        assignedModules: '',
        accountAccessModules: []
    });
    
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [modules, setModules] = useState<Module[]>([]);
    const [accessProfiles, setAccessProfiles] = useState<AccessProfileViewModel[]>([]);
    const [errors, setErrors] = useState<Partial<Record<keyof AccountViewModel, boolean>>>({});
    const [loading, setLoading] = useState(false);
    const [profileTypes, setProfileTypes] = useState<ProfileType[]>([]);
    const [claims, setClaims] = useState<TokenClaims>({} as TokenClaims);
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    
    const navigate = useNavigate();
    const location = useLocation();
    
    const { Option } = Select;
    
    useEffect(() => {
        if (location.state?.rowData) {
            setCurrentUser(location.state.rowData as AccountViewModel);
            setIsEditing(true);
        }
    }, [location.state]);
    
    useEffect(() => {
        const token = AuthenticationService.GetToken();
        if (token) {
            const decoded = DecodeToken(token);
            if (decoded) {
                setClaims(decoded);
            }
        }
    }, []);
    
    useEffect(() => {
        let isMounted = true;
    
        if (claims.accountid) {
            ModuleService.GetAllModules(claims.accountid).then((modulesResponse) => {
                if (isMounted) {
                    setModules(modulesResponse.data);
                }
            });
        }
    
        return () => {
            isMounted = false;
        };
    }, [claims]);
    
    const fetchModules = useCallback(async () => {
        const response = await ModuleService.GetAllModules(claims.accountid);
        if (response.success) {
            setModules(response.data);
        } else {
            console.error(response.message);
        }
    }, [claims]);
    
    const fetchProfileTypes = useCallback(async () => {
        const response = await ProfileTypeService.GetAllProfileTypes();
        if (response.success) {
            setProfileTypes(response.data);
        } else {
            console.error(response.message);
        }
    }, []);
    
    const fetchAccessProfiles = useCallback(async () => {
        const response = await AccessProfileService.GetAllAccessProfiles();
        if (response.success) {
            setAccessProfiles(response.data);
        } else {
            console.error(response.message);
        }
    }, []);
    
    useEffect(() => {
        fetchProfileTypes();
        fetchAccessProfiles();
        fetchModules();
    }, [fetchModules, fetchProfileTypes, fetchAccessProfiles]);
    
    const isValidEmail = (email: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    
    const handleValidation = () => {
        const validationErrors: Partial<Record<keyof AccountViewModel, boolean>> = {};
    
        const validationChecks = [
            { key: 'accessProfileId', message: 'É necessário selecionar um Nível de Acesso.', condition: currentUser.accessProfileId === '' },
            { key: 'profileTypeId', message: 'É necessário selecionar um Tipo de Perfil.', condition: currentUser.profileTypeId === '' },
            { key: 'name', message: 'O campo Nome é obrigatório.', condition: currentUser.name === '' },
            { key: 'email', message: 'O campo E-mail é obrigatório.', condition: !currentUser.email || !isValidEmail(currentUser.email) },
            { key: 'accountAccessModules', message: 'É necessário escolher um módulo para usuário.', condition: currentUser.accountAccessModules.length === 0 && claims.profileid?.toLowerCase() !== AccessProfileEnum.Administrator.toLowerCase() },
            { key: 'passwordHash', message: 'A senha deve ter no mínimo 8 caracteres.', condition: !currentUser.passwordHash || currentUser.passwordHash.length < 8 },
            { key: 'confirmPassword', message: 'O campo confirmar senha tem que conter o mesmo valor do campo senha.', condition: currentUser.passwordHash !== confirmPassword }
        ];
    
        validationChecks.forEach(({ key, message, condition }) => {
            if (condition) {
                CustomNotification({
                    title: 'Atenção!',
                    description: message,
                    notificationType: 'warning',
                    placement: 'top-right'
                });
                validationErrors[key as keyof AccountViewModel] = true;
            }
        });
    
        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };
    
    
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setErrors({}); 
    
        if (!handleValidation()) {
            return;
        }
    
        setLoading(true);
        try {
            const result = isEditing ? await AccountService.UpdateAccount(currentUser) : await AccountService.CreateAccount(currentUser);
            
            if (result.success) {
                CustomNotification({
                    title: 'Ação concluída com sucesso!',
                    description: isEditing ? "Usuário atualizado com sucesso!" : "Usuário criado com sucesso!",
                    notificationType: 'success',
                    placement: 'top-right'
                });
                navigate("/Workspace/Users");
            } else {
                throw new Error(result.message);
            }
        } catch (error) {
            CustomNotification({
                title: 'Algo deu errado!',
                description: 'Ocorreu um erro ao processar a solicitação.',
                notificationType: 'error',
                placement: 'top-right'
            });
        } finally {
            setLoading(false);
        }
    };
    
    const handleBack = () => navigate(-1);
    
    const handleMultiselectChange = (value: string[], name: keyof AccountViewModel) => {
        setCurrentUser(prev => ({
            ...prev,
            accountAccessModules: value.map(moduleId => ({
                id: '00000000-0000-0000-0000-000000000000',
                accountId: prev.id,
                moduleId: moduleId,
                status: true
            }))
        }));
    
        setErrors(prev => ({ ...prev, [name]: false }));
    };
    
    const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setCurrentUser(prev => ({ ...prev, [name]: value }));
        setErrors(prev => ({ ...prev, [name]: false }));
    }, []);
    
    const handleSelectChange = (name: keyof AccountViewModel) => (value: string) => {
        setCurrentUser(prev => ({ ...prev, [name]: value }));
        setErrors(prev => ({ ...prev, [name]: false }));
    };
    
    return(
        <div className='container-crud'>
            <Sidebar />
            <Navbar />
            <div className='container-content-crud'>
                <div className='container-header-crud'>
                    <span>{isEditing ? "Editando Usuário" : "Criando Novo Usuário"}</span>
                    <div className='button-header-crud'>
                        <CustomButton variant='secondary' labelText='Voltar' onClick={handleBack} />
                    </div>
                </div>

                <div className='container-custom-main-crud'>
                    <div className='container-title-crud'>
                        <span>Formulário de Usuário</span>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className='container-fields-crud'>
                            {loading && (
                                <Box
                                    position="absolute"
                                    top="0"
                                    left="0"
                                    width="100%"
                                    height="100%"
                                    bg="rgba(255, 255, 255, 0.8)"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    zIndex="10"
                                >
                                    <Spinner size="xl" />
                                </Box>
                            )} 
                            <div className='container-field-checkbox-crud'>
                                <Checkbox 
                                    checked={currentUser.status} 
                                    onChange={(e) => setCurrentUser(prev => ({ ...prev, status: e.target.checked }))} 
                                    className='container-custom-checkbox-input' 
                                />
                                <label>Ativo</label>
                            </div>

                            <div className='container-field-text-crud'>
                                <div className='field-text-name-complete-crud'> 
                                    <InputWithLabel 
                                        label='Nome' 
                                        placeholder='Nome' 
                                        name="name" 
                                        type='text'
                                        error={errors.name} 
                                        value={currentUser.name}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className='field-text-email-crud'> 
                                    <InputWithLabel 
                                        label='Email' 
                                        placeholder='E-Mail' 
                                        name="email" 
                                        type='email'
                                        error={errors.email} 
                                        value={currentUser.email}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>

                            <div className='container-multi-fields-crud'>
                                <div className={`field-select-crud ${errors.accessProfileId ? 'error-select' : ''}`}>
                                    <label>Nível de Acesso</label>
                                    <Select
                                        placeholder='Selecionar o Nível de Acesso'
                                        value={currentUser.accessProfileId || undefined}
                                        onChange={handleSelectChange('accessProfileId')}
                                    >
                                        {accessProfiles.map(option => (
                                            <Option key={option.id} value={option.id}>{option.name}</Option>
                                        ))}
                                    </Select>
                                </div>
                                <div className={`field-select-crud ${errors.profileTypeId ? 'error-select' : ''}`}>
                                    <label>Tipo de Perfil</label>
                                    <Select
                                        placeholder='Selecionar o Tipo de Perfil'
                                        value={currentUser.profileTypeId || undefined}
                                        onChange={handleSelectChange('profileTypeId')}
                                    >                                       
                                        {profileTypes.map(option => (
                                            <Option key={option.id} value={option.id}>{option.name}</Option>
                                        ))}
                                    </Select>
                                </div>
                                <div className={`field-multiselect-crud ${errors.accountAccessModules ? 'error-multiselect' : ''}`}>
                                    {currentUser.accessProfileId.toLowerCase() !== AccessProfileEnum.Administrator.toLowerCase() && (
                                        <>
                                            <label>Atribuição de Módulos</label>
                                            <Select
                                                mode="multiple"
                                                placeholder='Selecionar Módulos'
                                                onChange={(e) => handleMultiselectChange(e,'accountAccessModules')}
                                                value={currentUser.accountAccessModules.map((module) => module.moduleId)}
                                            >
                                                {modules.map(option => (
                                                    <Option key={option.id} value={option.id}>{option.name}</Option>
                                                ))}
                                            </Select>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className='container-field-text-crud'>
                                <div className='field-text-password-crud'> 
                                    <InputWithLabel 
                                        label='Senha' 
                                        placeholder='Digite uma senha...' 
                                        name="passwordHash" 
                                        type='password'
                                        error={errors.passwordHash} 
                                        value={currentUser.passwordHash}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className='field-text-password-crud'> 
                                    <InputWithLabel 
                                        label='Confirme a senha' 
                                        placeholder='Confirme a senha digitada...' 
                                        name="confirmPassword" 
                                        type='password'
                                        error={errors.confirmPassword} 
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div>
                                <CustomButton type="submit" variant='primary' labelText={isEditing ? 'Atualizar' : 'Confirmar'} />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default CreateOrEditUser;