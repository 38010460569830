import React, { useEffect, useState, useCallback, useRef } from 'react';
import "./style.css";
import { TokenClaims } from '../../types/token-claims';
import AuthenticationService from 'services/login/auth-service';
import { DecodeToken } from 'utils/decode-token';
import Navbar from 'components/header';
import Sidebar from 'components/sidebar';
import Menu from 'components/menu';
import { TicketStatus } from '../../types/enum/ticket-status-enum-types';
import Popover from 'antd/es/popover';
import Filter from 'components/filter';
import CustomButtonIcon from 'components/custom-button-icon';
import { Module } from '../../types/module-types';
import { TicketType } from '../../types/ticket-type-types';
import { AccountViewModel } from '../../types/models/account-view-model';
import IconFilter from '../../assets/icon_filter.png';
import IconShared from '../../assets/icon_shared.png';
import { FilterState } from '../../types/filter-state-types';
import CustomTablePaginationButton from 'components/custom-table-pagination-button';
import CustomTableTicket from 'components/custom-table-pagination';
import ModuleService from 'services/module-service';
import TicketTypeService from 'services/ticket-type-service';
import AccountService from 'services/account-service';
import { PriorityCount } from '../../types/priority-count-types';
import editIcon from '../../assets/icon_edit.png'; 
import { TicketViewModel } from '../../types/models/ticket-view-model';
import { format } from 'date-fns';
import { Modal } from 'react-bootstrap';
import TicketManage from 'pages/ticket-manage';
import RespostTicket from 'pages/respost-ticket';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { formatClassTicketStatus } from 'utils/ticket-status-helpers';

function ManageTickets() {
    const [claims, setClaims] = useState<TokenClaims>({} as TokenClaims);
    const [priority, setPriority] = useState<PriorityCount | undefined>();
    const [modules, setModules] = useState<Module[]>([]);
    const [ticketTypes, setTicketTypes] = useState<TicketType[]>([]);
    const [operators, setOperators] = useState<AccountViewModel[]>([]);
    const [filters, setFilters] = useState<FilterState>({} as FilterState);
    const [listTicketStatus, setListTicketStatus] = useState<TicketStatus[]>([]);
    const [selectedTicketId, setSelectedTicketId] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [tableKey, setTableKey] = useState(0);
    const [menuKey, setMenuKey] = useState(0);
    const [modalRespostShow, setModalRespostShow] = useState(false);
    const listInProgress = [TicketStatus.EmAndamento, TicketStatus.EsperandoAnexos, TicketStatus.Reaberto];
    const listInOpen = [TicketStatus.Registrado];
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        const token = AuthenticationService.GetToken();
        if (token) {
            const decoded = DecodeToken(token);
            if (decoded) {
                setClaims(decoded);
            }
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            if (claims.accountid) {
                try {
                    const [modulesResponse, ticketTypesResponse, accountResponse] = await Promise.all([
                        ModuleService.GetAllModules(claims.accountid),
                        TicketTypeService.GetAll(),
                        AccountService.GetAllOperatorsByAccountId(claims.accountid, claims.profileid)
                    ]);
                    setModules(modulesResponse.data);
                    setTicketTypes(ticketTypesResponse.data);
                    setOperators(accountResponse.data);
                } catch (error) {
                    console.error("Error fetching data", error);
                }
            }
        };

        fetchData();
    }, [claims]);

    const ticketId = searchParams.get('TicketId');

    useEffect(() => {
        if (ticketId) {
            setSelectedTicketId(ticketId);
            setModalShow(true);
        }
    }, [ticketId]);

    const handleCloseModal = () => setModalShow(false);
    
    const handleShowModal = (ticketId: string) => {
        setSelectedTicketId(ticketId);
        setModalShow(true);
    }; 

    const handleTicketCreated = () => {
        setTableKey(prevKey => prevKey + 1);
        setMenuKey(prevKey => prevKey + 1);
        handleCloseModal();
    };
    
    const handleCancel = () => {
        navigate('/Manage', { replace: true });
        handleCloseModal();
    };

    const handleRespostClose = () => setModalRespostShow(false);

    const handleRespostShow = () => setModalRespostShow(true); 

    const handleLoadingStatus = (status: boolean) => {
        setIsLoading(status);
    };

    const handleVisibleChange = useCallback((visible: boolean) => {
        setVisible(visible);
    }, []);

    const handleUpdateListTicketStatus = useCallback((status: TicketStatus[]) => {
        setListTicketStatus(status);
    }, []);

    const handleFiltersChange = useCallback((filters: FilterState) => {
        setVisible(false);
        setFilters(filters);
        setTableKey(prevKey => prevKey + 1);
        setMenuKey(prevKey => prevKey + 1);
    }, []);

    const handleUpdatePriority = useCallback((priority?: PriorityCount) => {
        setPriority(priority);
    }, []);

    const formatRequestDate = (rowData: TicketViewModel) => {
        const formattedDate = rowData.requestDate 
            ? format(new Date(rowData.requestDate), "dd/MM/yyyy - HH'h'") 
            : 'Data não disponível';
        return <div className='container-request-date'>{formattedDate}</div>;
    };

    const formatPriorityName = (rowData: TicketViewModel) => (
        <div className="container-tag-priority">
            <div className='tag-priority' style={{ backgroundColor: rowData.priorityId ? rowData.priorityHexadecimal : '#ddd' }}>
                {rowData.priorityName}
            </div>
        </div>
    );

    const formatOperatorName = (rowData: TicketViewModel) => {
        return rowData.operatorName || 'Não Atribuído';
    };

    const formatSubject = (rowData: TicketViewModel) => (
        <div className='container-subject-table'>{rowData.subject}</div>
    );

    const formatDetailedDescription = (rowData: TicketViewModel) => (
        <div className='container-detailed-description-table'>{rowData.detailedDescription}</div>
    );

    const formatTicketStatus = (rowData: TicketViewModel) => {
        return (
            <div className='container-ticket-status-table'>
                {rowData.ticketStatusName}
                <div className={`eclipse-ticket-status ${formatClassTicketStatus[rowData.ticketStatusId.toUpperCase() as TicketStatus] || formatClassTicketStatus[TicketStatus.Registrado]}`}></div>
            </div>
        );
    };

    const formatEditTicket = (rowData: TicketViewModel) => (
        <div className='container-preview-attachment'>
            <img src={editIcon} alt="Ícone de editar" onClick={() => handleShowModal(rowData.id)} />
        </div>
    );

    const columns = [
        { title: 'ID', dataIndex: 'ticketNumber', key: 'ticketNumber' },
        { title: 'Data e Hora', render: (text: TicketViewModel) => formatRequestDate(text), key: 'requestDate' },
        { title: 'Tipo', dataIndex: 'ticketTypeName', key: 'ticketTypeName' },
        { title: 'Prioridade', render: (text: TicketViewModel) => formatPriorityName(text), key: 'priority' },
        { title: 'Solicitante', dataIndex: 'requesterName', key: 'requesterName', onCell: () => ({ style: { minWidth: '150px' } }) },
        { title: 'Operador Responsável', render: (text: TicketViewModel) => formatOperatorName(text), key: 'operator', onCell: () => ({ style: { minWidth: '132px', maxWidth: '132px' } }) },
        { title: 'Módulo', dataIndex: 'moduleName', key: 'moduleName' },
        { title: 'Assunto', render: (text: TicketViewModel) => formatSubject(text), key: 'subject' },
        { title: 'Descrição Detalhada', render: (text: TicketViewModel) => formatDetailedDescription(text), key: 'detailedDescription' },
        { title: 'Status', render: (text: TicketViewModel) => formatTicketStatus(text), key: 'status' },
        { title: 'Editar', render: (text: TicketViewModel) => formatEditTicket(text), key: 'edit' },
    ];

    const renderTable = useCallback(() => {
        const listAllTicketStatus = new Set(Object.values(TicketStatus));
        const isAllTicketStatus = Array.from(listAllTicketStatus).every(status => listTicketStatus.includes(status));

        if (isAllTicketStatus) {
            return (
                <div>
                    <CustomTablePaginationButton columns={columns} priorityId={priority?.id} filters={filters} labelText='Abertos' key={tableKey} listTicketStatus={listInOpen} />
                    <div className='container-table-in-progress'>
                        <CustomTablePaginationButton columns={columns} filters={filters} labelText='Em Andamento' key={tableKey} listTicketStatus={listInProgress} />
                    </div>
                </div>
            );
        } else {
            return (
                <CustomTableTicket columns={columns} priority={priority} filters={filters} key={tableKey} listTicketStatus={listTicketStatus} />
            );
        }
    }, [filters, listTicketStatus, priority, tableKey]);

    return (
        <div className='container-main-manage'>
            <Sidebar />
            <Navbar />
            <div className='container-content-manage'>
                <Menu key={menuKey} onUpdatePriority={handleUpdatePriority} onUpdateListTicketStatus={handleUpdateListTicketStatus} />
                <div className='container-sub-content-manage'>
                    <div className='container-button-manage'>
                        <Popover
                            overlayClassName='container-manage-filter'
                            placement="bottomLeft"
                            content={<Filter modules={modules} ticketTypes={ticketTypes} operators={operators} onFiltersChange={handleFiltersChange} />}
                            open={visible}
                            onOpenChange={handleVisibleChange}
                        >
                            <div className='button-filter-manage'>
                                <CustomButtonIcon iconSrc={IconFilter} labelText='Filtros' variant='secondary' />
                            </div>
                        </Popover>
                        <div className='button-repost-ticket-manage'>
                            <CustomButtonIcon iconSrc={IconShared} labelText='Repassar Chamados' variant='secondary' onClick={handleRespostShow} />
                        </div>
                    </div>
                    <div className='container-title-manage'>
                        <span>Meus Chamados</span>
                    </div>
                    <div className='container-table-content-manage'>
                        {renderTable()}
                    </div>
                </div>
            </div>

            <Modal 
                show={modalShow}
                onHide={handleCancel}
                className="container-custom-modal"
                backdrop={isLoading ? 'static' : true}
                keyboard={!isLoading} 
            >
                <Modal.Body>
                    <TicketManage 
                        ticketId={selectedTicketId}
                        onTicketUpdated={handleTicketCreated} 
                        onCancel={handleCancel}
                        setLoading={handleLoadingStatus}
                    />
                </Modal.Body>
            </Modal>

            <RespostTicket 
                isOpen={modalRespostShow}
                modules={modules} 
                operators={operators}
                handleClose={handleRespostClose} 
            />
        </div>
    );
}

export default ManageTickets;