import Config from 'config';
import { Module } from '../types/module-types';
import { ApiResponse, PagedResult, PaginationDefaultRequest, PostApiResponse } from '../types/response/api-types';
import AuthenticationService from './login/auth-service';

const API_URL = `${Config.API_URL}${Config.Endpoints.Module}`;

const ModuleService = {
    CreateModule: async(module: Module): Promise<PostApiResponse<boolean>> => { 
        try {
            const token = AuthenticationService.GetToken();
            const requestData = {
                Module: module
            };

            const response = await fetch(`${API_URL}/createmodule`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`  
                },
                body: JSON.stringify(requestData),
            });

            const data: PostApiResponse<boolean> = await response.json();

            if (data.success) {
                return data;
            } else {
                return {
                    success: false,
                    message: "Falha ao criar novo módulo.",
                    data: false
                };
            }
        } catch (error) {
            return {
                success: false,
                message: "Falha ao criar novo módulo.",
                data: false
            };
        }
    },
    UpdateModule: async(module: Module): Promise<PostApiResponse<boolean>> => { 
        try {
            const token = AuthenticationService.GetToken();
            const requestData = {
                Module: module
            };

            const response = await fetch(`${API_URL}/updatemodule`, {
                method: 'PUT',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`  
                },
                body: JSON.stringify(requestData),
            });

            const data: PostApiResponse<boolean> = await response.json();
            if (data.success) {
                return data;
            } else {
                return {
                    success: false,
                    message: "Falha ao tipo de perfil.",
                    data: false
                };
            }
        } catch (error) {
            return {
                success: false,
                message: "Falha ao tipo de perfil",
                data: false
            };
        }
    },
    GetPagedModules: async (request: PaginationDefaultRequest): Promise<PostApiResponse<PagedResult<Module>>> => {
        try {
            const token = AuthenticationService.GetToken();
            const response = await fetch(`${API_URL}/getpagedmodules`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` 
                },
                body: JSON.stringify(request)
            });

            const data: PostApiResponse<PagedResult<Module>> = await response.json();
            if (data.success) {
                return data;
            } else {
                return {
                    success: false,
                    message: "Falha ao obter os módulos.",
                    data: {} as  PagedResult<Module>
                };
            }
        } catch (error) {
            return {
                success: false,
                message: "Falha ao obter os módulos.",
                data: {} as  PagedResult<Module> 
            };
        }
    },
    GetAllModules: async (accountId: string): Promise<ApiResponse<Module>> => {
        try {
            const token = AuthenticationService.GetToken();
            const response = await fetch(`${API_URL}/getallmodules`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` 
                },
                body: JSON.stringify({ SearchTerm: "", AccountId: accountId})
            });

            const data: ApiResponse<Module> = await response.json();
            if (data.success) {
                return data;
            } else {
                return {
                    success: false,
                    message: "Falha ao obter os módulos.",
                    data: [] 
                };
            }
        } catch (error) {
            return {
                success: false,
                message: "Falha ao obter os módulos.",
                data: [] 
            };
        }
    }
}

export default ModuleService;
