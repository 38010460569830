import React, { useState } from 'react';
import "./style.css";
import IconFilter from '../../assets/icon_filter.png';
import { Checkbox, Select } from 'antd';
import CustomButton from 'components/custom-button';
import { FilterState } from '../../types/filter-state-types';
import { Module } from '../../types/module-types';
import { TicketType } from '../../types/ticket-type-types';
import { AccountViewModel } from '../../types/models/account-view-model';

interface FilterProps {
    onFiltersChange: (filters: FilterState) => void;
    modules: Module[];
    ticketTypes: TicketType[];
    operators: AccountViewModel[];
}

const Filter: React.FC<FilterProps> = ({ onFiltersChange, modules, ticketTypes, operators }) => {
    const [filters, setFilters] = useState<FilterState>({
        ticketTypeId: undefined,
        moduleId: undefined,
        periodDays: undefined,
        operatorId: undefined,
    });

    const [ticketTypeChecked, setTicketTypeChecked] = useState(false);
    const [moduleChecked, setModuleChecked] = useState(false);
    const [dateChecked, setDateChecked] = useState(false);
    const [operatorChecked, setOperatorChecked] = useState(false);

    const { Option } = Select;

    const handleSelectChange = (value: string | number, filterKey: keyof FilterState, setChecked: React.Dispatch<React.SetStateAction<boolean>>) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [filterKey]: value,
        }));
        setChecked(!!value);
    };

    const handleCancel = () => {
        const reset: FilterState = {
            ticketTypeId: undefined,
            moduleId: undefined,
            periodDays: undefined,
            operatorId: undefined,
        };
        
        setFilters(reset);
        setTicketTypeChecked(false);
        setModuleChecked(false);
        setDateChecked(false);
        setOperatorChecked(false);
        
        onFiltersChange(reset);
    };

    const applyFilters = () => {
        onFiltersChange(filters);
    };

    return (
        <div className='container-main-filter'>
            <div className='container-title-filter'>
                <img src={IconFilter} className="button-icon" alt="Filter Icon" />
                <span>Você deseja filtrar por:</span>
            </div>

            <div className='container-content-filter'>
                <div className='container-selects-content-filter'>
                    <div className='container-left-select-filter'>
                        <Checkbox 
                            className='container-custom-checkbox-input' 
                            checked={ticketTypeChecked}
                            onChange={() => {
                                const newChecked = !ticketTypeChecked;
                                setTicketTypeChecked(newChecked);
                                if (!newChecked) setFilters(prev => ({ ...prev, ticketTypeId: undefined })); // Limpa o value ao desmarcar
                            }} 
                        />
                        <div className='field-select-crud'>
                            <label>Tipo</label>
                            <Select
                                placeholder='Tipo'
                                value={filters.ticketTypeId}
                                onChange={(value) => handleSelectChange(value, 'ticketTypeId', setTicketTypeChecked)}
                            >
                                {ticketTypes.map(option => (
                                    <Option key={option.id} value={option.id}>{option.name}</Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                    <div className='container-right-select-filter'>
                        <Checkbox 
                            className='container-custom-checkbox-input' 
                            checked={moduleChecked}
                            onChange={() => {
                                const newChecked = !moduleChecked;
                                setModuleChecked(newChecked);
                                if (!newChecked) setFilters(prev => ({ ...prev, moduleId: undefined }));
                            }} 
                        />
                        <div className='field-select-crud'>
                            <label>Módulo</label>
                            <Select
                                placeholder='Selecionar Módulo'
                                value={filters.moduleId}
                                onChange={(value) => handleSelectChange(value, 'moduleId', setModuleChecked)}
                            >
                                {modules.map(option => (
                                    <Option key={option.id} value={option.id}>{option.name}</Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='container-selects-content-filter'>
                        <div className='container-left-select-filter'>
                            <Checkbox 
                                className='container-custom-checkbox-input' 
                                checked={dateChecked}
                                onChange={() => {
                                    const newChecked = !dateChecked;
                                    setDateChecked(newChecked);
                                    if (!newChecked) setFilters(prev => ({ ...prev, date: undefined }));
                                }} 
                            />
                            <div className='field-select-crud'>
                                <label>Data</label>
                                <Select
                                    placeholder='Selecionar Data'
                                    value={filters.periodDays}
                                    onChange={(value) => handleSelectChange(value, 'periodDays', setDateChecked)}
                                >
                                    <Option key={1} value={1}>1 Dia atrás</Option>
                                    <Option key={7} value={7}>1 Semana atrás</Option>
                                    <Option key={30} value={30}>1 Mês atrás</Option>
                                </Select>
                            </div>
                        </div>
                        <div className='container-right-select-filter'>
                            <Checkbox 
                                className='container-custom-checkbox-input' 
                                checked={operatorChecked}
                                disabled={!filters.moduleId}
                                onChange={() => {
                                    const newChecked = !operatorChecked;
                                    setOperatorChecked(newChecked);
                                    if (!newChecked) setFilters(prev => ({ ...prev, operatorId: undefined }));
                                }} 
                            />
                            <div className='field-select-crud'>
                                <label className={!filters.moduleId ? 'disabled-label' : ''}>Operador do Módulo</label>
                                <Select
                                    placeholder='Selecionar Operador do Módulo acima'
                                    value={filters.operatorId}
                                    disabled={!filters.moduleId}
                                    onChange={(value) => handleSelectChange(value, 'operatorId', setOperatorChecked)}
                                >
                                    {operators.filter(operator => operator.accountAccessModules.some(access => access.moduleId  === filters.moduleId)).map(option => (
                                        <Option key={option.id} value={option.id}>{option.name}</Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container-buttons-content-filter'>
                    <div className='filter-button-cancel-filter'>
                        <CustomButton labelText='Cancelar' variant='primary' onClick={handleCancel} />
                    </div>
                    <div className='filter-button-apply-filter'>
                        <CustomButton labelText='Aplicar Filtros' variant='primary' onClick={applyFilters} type="button" />
                    </div>      
                </div>
            </div>
        </div>
    );
};

export default Filter;